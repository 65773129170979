import { string } from "prop-types";

import classnames from "~utils/classnames";

import classes from "./Background750.module.scss";

const Background750 = ({ className }) => {
  return <div className={classnames(classes.background, className)} />;
};

Background750.propTypes = {
  className: string,
};

export default Background750;
