import Image from "next/image";
import { useTranslation } from "next-i18next";
import { useContext } from "react";

import Accordion from "~components/Accordion";
import Link from "~components/Link";
import SocialMedia from "~components/SocialMedia";
import { NavigationContext } from "~contexts/NavigationContext";
import resolveDomainUrl from "~lib/urls/resolveDomainUrl";
import classnames from "~utils/classnames";
import { toValidUrl } from "~utils/routing";

import classes from "./Footer.module.scss";

const Footer = () => {
  const { domains, currentDomain, handleSelectDomain, subFooter } =
    useContext(NavigationContext);
  const { t } = useTranslation();

  const accordionItems = currentDomain?.footerItems.map((item) => ({
    title: item.title,
    content: item.links
      .map((link) => (
        <Link
          key={item.id}
          referenceId={item.id}
          href={link.slug ? toValidUrl(link.slug) : "/placeholder-slug"}
        >
          {link.title}
        </Link>
      ))
      .concat(
        item.externalLinks?.map((link) => (
          <Link
            key={link.id}
            href={link.url}
            rel="noopener noreferrer"
            target="_blank"
            external
          >
            {link.label}
          </Link>
        )) ?? [],
      ),
  }));

  // Function to open the CookieBot consent popup
  const handleCookieSettingsClick = () => {
    if (window && window?.CookieConsent) {
      window.CookieConsent.show();
    }
  };

  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        <div className={classes.row}>
          <div className={classes.column}>
            <Image
              src="/images/iamsterdam-logo-white.svg"
              alt="iAmsterdam logo"
              width={135}
              height={18}
            />
            {currentDomain.subtext && (
              <div className={classes.subtext}>{currentDomain.subtext}</div>
            )}
          </div>
          {domains.map((domain) => (
            <div className={classes.column} key={domain.id}>
              <Link
                href={resolveDomainUrl(domain)}
                onClick={() => handleSelectDomain(domain.id)}
                className={classnames(
                  classes.domain,
                  currentDomain.id === domain.id && classes.domain_active,
                )}
              >
                {domain.title}
              </Link>
            </div>
          ))}
          <div className={classes.border} />
        </div>
        <div className={classes.row}>
          <div className={classes.column_socials}>
            <h2 className={classes.socials_title}>{t("followUs")}</h2>
            {currentDomain.socials.map((social) => (
              <SocialMedia
                key={social.id}
                platform={social.platform}
                handle={social.handle}
                url={social.url}
                color="white"
              />
            ))}
          </div>
          {currentDomain.footerItems?.map(
            ({ id, title, links, externalLinks }) => (
              <div key={id} className={classes.column_list}>
                <h2 className={classes.list_title}>{title}</h2>
                <ul>
                  {links.map(({ title, slug }) => (
                    <li key={slug}>
                      <Link
                        href={slug ? toValidUrl(slug) : "/placeholder-slug"}
                      >
                        {title}
                      </Link>
                    </li>
                  ))}
                  {externalLinks?.map(({ label, url }) => (
                    <li key={url}>
                      <Link href={url} external>
                        {label}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ),
          )}
          <div className={classes.column_accordion}>
            <Accordion items={accordionItems} />
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.sub_footer}>
            © {subFooter.copyright} I amsterdam
          </div>
          {subFooter.links.map(({ id, title, slug }) => (
            <p key={id} className={classes.sub_footer}>
              <Link href={toValidUrl(`/${slug}`)}>{title}</Link>
            </p>
          ))}
          {/* Cookie-instellingen Link */}
          <div className={classes.sub_footer}>
            <button
              className={classes.button}
              aria-label={t("cookiebotLabel")}
              onClick={handleCookieSettingsClick}
            >
              <span> {t("cookiebotLabel")}</span>
            </button>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
