const { defaultLocale } = require("~config/locale");
const { PRODUCT_TYPE } = require("~config/shop");

const findRoute = (routes, model, productType, locale) => {
  const route = routes.find(
    (route) =>
      route.model === model &&
      route.productType === productType &&
      route.locale === locale,
  );
  // fallback to EN if not found
  if (!route && locale !== "en") {
    return routes.find(
      (route) =>
        route.model === model &&
        route.productType === productType &&
        route.locale === "en",
    );
  }
  return route;
};

const getShopProductPageRoute = (
  locale,
  productType = PRODUCT_TYPE.CITY_CARD,
) => {
  const routes = require("../../backend/files/routes.json");
  const route = findRoute(routes, "ProductSelectorPage", productType, locale);
  const prefix = locale === defaultLocale ? "" : `/${locale}`;
  return route ? `${prefix}/${route.slug}` : `${prefix}/shop/${productType}`;
};

const getShopCheckoutPageRoute = (
  locale,
  productType = PRODUCT_TYPE.CITY_CARD,
) => {
  const routes = require("../../backend/files/routes.json");
  const route = findRoute(routes, "ProductCheckoutPage", productType, locale);
  const prefix = locale === defaultLocale ? "" : `/${locale}`;
  return route
    ? `${prefix}/${route.slug}`
    : `${prefix}/shop/${productType}/checkout`;
};

module.exports = {
  getShopProductPageRoute,
  getShopCheckoutPageRoute,
};
