import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { memo, useContext, useEffect } from "react";

import {
  getShopCheckoutPageRoute,
  getShopProductPageRoute,
} from "~common/quickRoutes";
import Button from "~components/Button";
import { PRODUCT_TYPE } from "~config/shop";
import { ShopBasketContext } from "~contexts/ShopBasketContext";
import Creditcard from "~public/icons/creditcard.svg";

const PRODUCT_SELECTOR_PAGE_ROUTE = "/shop/products/[preprId]";

const ShopCheckoutButton = memo(() => {
  const { t } = useTranslation();
  const {
    locale,
    asPath,
    pathname,
    query: { productType },
  } = useRouter();
  const { shopBasket, requestLoad } = useContext(ShopBasketContext);
  // when on the city card product overview page AND no city card products are in the basket
  // then the button should link to the product block on the same page
  const isCityCardProductOverviewPage =
    PRODUCT_SELECTOR_PAGE_ROUTE === pathname &&
    productType === PRODUCT_TYPE.CITY_CARD;

  useEffect(() => {
    if (!shopBasket) {
      requestLoad();
    }
  }, [requestLoad, shopBasket]);

  const goToPage = (event) => {
    event.preventDefault();
    // because of a bug in next.js that they don't feel like fixing
    // (see https://github.com/vercel/next.js/discussions/26426)
    // linking to an url that is defined in the redirects in next.config.js
    // does not work an results in a black page, so do a full page load instead
    window.location.href = event.currentTarget.href;
  };

  const hasCityCardProducts =
    shopBasket?.products.length &&
    shopBasket?.productGroup?.productType === PRODUCT_TYPE.CITY_CARD;

  let href = getShopProductPageRoute(locale, PRODUCT_TYPE.CITY_CARD);
  let shouldRefresh = true;
  if (!hasCityCardProducts && isCityCardProductOverviewPage) {
    shouldRefresh = false;
    href = `${asPath.split("#")[0]}#products`;
  } else if (hasCityCardProducts) {
    href = getShopCheckoutPageRoute(locale, PRODUCT_TYPE.CITY_CARD);
  }

  return hasCityCardProducts ? (
    <Button
      variant="solid"
      color="secondary"
      href={href}
      onClick={shouldRefresh ? goToPage : null}
      endIcon={<Creditcard style={{ marginTop: "-4px" }} />}
    >
      {t("completeOrder")}
    </Button>
  ) : (
    <Button
      variant="solid"
      color="secondary"
      href={href}
      onClick={shouldRefresh ? goToPage : null}
    >
      {t("orderCityCard")}
    </Button>
  );
});

ShopCheckoutButton.displayName = "ShopCheckoutButton";

export default ShopCheckoutButton;
