/**
 * @typedef TicketType
 * @property {number} rateFrom
 * @property {number} rateUntil
 * @property {string} extraInfo - This is the ticket type.
 * @property {boolean} freeEntrance
 */

/**
 * Determins what ticket option is regarded as a regular ticket.
 * Ticket type (extraInfo) order of imortance: Adults > Children > null.
 * It then takes the lowest rateFrom found in a ticket type.
 * @param {Array<TicketType>} ticketOptions
 * @return {TicketType || null}
 */
const getRegularTicket = (ticketOptions) => {
  if (!Array.isArray(ticketOptions)) {
    return null;
  }

  const getTicketByType = (type) =>
    ticketOptions
      .filter(({ extraInfo }) => extraInfo === type)
      .reduce(
        (acc, ticket) => (acc && acc.rateFrom < ticket.rateFrom ? acc : ticket),
        null,
      );

  const adultTicket = getTicketByType("Adults");
  const childTicket = getTicketByType("Children");
  const nondescriptTicket = getTicketByType(null);

  return adultTicket || childTicket || nondescriptTicket;
};

export default getRegularTicket;
