import { node } from "prop-types";
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

// eslint ignores are needed for the context and type checking
export const ToastContext = createContext({
  toasts: [],
  // eslint-disable-next-line no-unused-vars
  addToast: (icon, title, description) => {},
  // eslint-disable-next-line no-unused-vars
  removeToast: (id) => {},
});

export const useToast = () => {
  return useContext(ToastContext);
};

let toastId = 0;

export const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);

  const addToast = useCallback((icon, title, description) => {
    const id = toastId++;
    setToasts((prevToasts) => {
      const newToasts = [...prevToasts, { id, icon, title, description }];
      if (newToasts.length > 3) {
        newToasts.shift();
      }
      return newToasts;
    });
  }, []);

  const removeToast = useCallback((id) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  }, []);

  const value = useMemo(
    () => ({ toasts, addToast, removeToast }),
    [toasts, addToast, removeToast],
  );

  return (
    <ToastContext.Provider value={value}>{children}</ToastContext.Provider>
  );
};

ToastProvider.propTypes = {
  children: node.isRequired,
};
