import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { arrayOf, shape, string } from "prop-types";
import { useEffect, useRef, useState } from "react";

import { useQuizColors } from "~contexts/QuizProfileContext";
import useInViewPort from "~hooks/useInViewPort";

import classes from "./PrizeList.module.scss";
import PrizeListCard, { PrizeListCardType } from "./PrizeListCard";

gsap.registerPlugin(useGSAP);

const PrizeList = ({ title, items }) => {
  const container = useRef(null);
  const listRef = useRef(null);
  const [rootMargin, setRootMargin] = useState("-100px"); // Default value for SSR
  const isInViewPortOnce = useInViewPort(container, { observeOnce: true });

  const isInViewPort = useInViewPort(listRef, { rootMargin });

  const { setBackgroundColor } = useQuizColors();

  useEffect(() => {
    // Calculate rootMargin after component mounts
    const margin = `-${Math.min(400, window.innerHeight * 0.3)}px`;
    setRootMargin(margin);
  }, []);

  useGSAP(
    () => {
      if (!isInViewPortOnce) {
        return;
      }
      const slideInSelector = "[data-animation-id='slideIn']";
      gsap.fromTo(
        slideInSelector,
        { x: 100, opacity: 0 },
        {
          x: 0,
          opacity: 1,
          duration: 0.8,
          stagger: 0.2,
          ease: "power2.out",
        },
      );
    },
    { scope: container, dependencies: [isInViewPortOnce] },
  );

  useEffect(() => {
    setBackgroundColor(
      isInViewPort ? "var(--default-quiz-background-color)" : null,
    );
  }, [isInViewPort, setBackgroundColor]);

  return (
    <section className={classes.section} id="prize-list" ref={container}>
      <h2 data-animation-id="slideIn" className={classes.title}>
        {title}
      </h2>
      <div data-animation-id="slideIn" className={classes.list} ref={listRef}>
        {items.map((item) => (
          <PrizeListCard key={item.id} {...item} />
        ))}
      </div>
    </section>
  );
};

PrizeList.propTypes = {
  title: string.isRequired,
  items: arrayOf(shape(PrizeListCardType)).isRequired,
};

export default PrizeList;
