import { bool, number, oneOf, string } from "prop-types";

import classnames from "~utils/classnames";

import classes from "./Icon.module.scss";
import iconFiles from "./icons";

const Icon = ({
  type = "checkmark",
  size = 1,
  color,
  focusable,
  removeIconDefaultSize,
  className,
}) => {
  if (!iconFiles[type]) {
    console.error(`Icon not found for type ${type}.`);
    return;
  }

  const Component = iconFiles[type] ?? iconFiles.checkmark;

  return (
    <div
      className={classnames(
        className,
        classes.icon,
        color && classes[`icon_${color}`],
      )}
      style={
        !removeIconDefaultSize
          ? { height: `${size}rem`, width: `${size}rem` }
          : {}
      }
    >
      <Component focusable={focusable ? "true" : "false"} />
    </div>
  );
};

export const propTypes = {
  type: oneOf(Object.keys(iconFiles)),
  size: number,
  color: oneOf(["primary", "secondary", "black", "white"]),
  focusable: bool,
  className: string,
  removeIconDefaultSize: bool,
};

Icon.propTypes = propTypes;

export default Icon;
