import { differenceInSeconds, parseISO } from "date-fns";

/**
 * Calculates the time left from the current date and time to the specified target date.
 *
 * @param {string} date - The target date in ISO 8601 format.
 * @returns {Object|null} An object representing the time left with properties for days, hours, minutes, and seconds.
 *                        Returns null if the target date is in the past.
 *
 * @example
 * // Assuming the current date is 2024-05-28T12:00:00Z
 * calculateTimeLeft('2024-06-01T12:00:00Z');
 * // Returns: { days: 4, hours: 0, minutes: 0, seconds: 0 }
 */

const secondsInADay = 86400;
const secondsInAnHour = 3600;
const secondsInAMinute = 60;

export const calculateTimeLeft = (date) => {
  const targetDate = parseISO(date);
  const now = new Date();

  const userTimezoneOffset = now.getTimezoneOffset() * 60 * 1000;

  const adjustedTargetDate = new Date(
    targetDate.getTime() + userTimezoneOffset,
  );

  const difference = differenceInSeconds(adjustedTargetDate, now);
  if (difference <= 0) {
    return null;
  }

  return {
    days: Math.floor(difference / secondsInADay),
    hours: Math.floor((difference % secondsInADay) / secondsInAnHour),
    minutes: Math.floor((difference % secondsInAnHour) / secondsInAMinute),
    seconds: difference % secondsInAMinute,
  };
};
