import { arrayOf, node } from "prop-types";
import { createContext, useContext, useMemo } from "react";

import { TagLabelType } from "~types";

const TagLabelsContext = createContext(null);

/**
 * @typedef {object} TagReference
 * @property {string} body
 *
 * @typedef {object} TagLabel
 * @property {string} id
 * @property {string} labelTitle
 * @property {Array<TagReference>} tagReference
 */

export const useTagLabels = () => useContext(TagLabelsContext);

export const TagLabelsProvider = ({ data, children }) => {
  const value = useMemo(
    () => ({
      /**
       * @param {TagReference[]} tags
       * @returns {TagLabel[] | null}
       */
      getTagLabel: (tags) => {
        if (!tags || tags.length === 0 || !data?.length) {
          return null;
        }

        const tagLabels = tags
          .map((tag) =>
            data.find(
              (tagLabel) =>
                tagLabel?.tagReference?.[0].body === tag.body &&
                tagLabel.showLabel &&
                tagLabel.labelTitle,
            ),
          )
          .filter(Boolean);

        return tagLabels.length > 0 ? tagLabels : null;
      },
    }),
    [data],
  );

  return (
    <TagLabelsContext.Provider value={value}>
      {children}
    </TagLabelsContext.Provider>
  );
};

TagLabelsProvider.propTypes = {
  data: arrayOf(TagLabelType).isRequired,
  children: node.isRequired,
};

export default TagLabelsContext;
