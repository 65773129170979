import { addMinutes } from "date-fns/addMinutes";
import { format } from "date-fns/format";
import { de, enGB as en, es, fr, it, nl, pt } from "date-fns/locale";

const dateLocale = {
  nl,
  en,
  pt,
  es,
  it,
  de,
  fr,
};

// Define an array for locales that require "EEEEEE" to be changed to "EEE"
const localesToChangeDayNames = ["en"];

const formatDate = (dateString, locale = "nl", pattern = "PPPP") => {
  const offset = new Date(dateString).getTimezoneOffset();
  const date = addMinutes(new Date(dateString), offset);

  // Check if the locale is in the list and the pattern contains "EEEEEE"
  if (localesToChangeDayNames.includes(locale) && pattern.includes("EEEEEE")) {
    pattern = pattern.replace("EEEEEE", "EEE");
  }

  let formattedDate = format(new Date(date), pattern, {
    locale: dateLocale[locale] ?? dateLocale.en,
  });

  // Remove trailing period in month's short name
  if (pattern.includes("MMM")) {
    formattedDate = formattedDate.replace(/\.(?=\s|$)/g, "");
  }

  return formattedDate;
};

export default formatDate;
