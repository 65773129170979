import { useTranslation } from "next-i18next";
import { func, string } from "prop-types";
import { useEffect, useState } from "react";

import { calculateTimeLeft } from "~utils/calculateTimeLeft";

import classes from "./Countdown.module.scss";

const Countdown = ({ date, onCountdownEnd }) => {
  const { t } = useTranslation();
  const [timeLeft, setTimeLeft] = useState(null);
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  useEffect(() => {
    if (!isClient || typeof window === "undefined") {
      return;
    }

    const timer = setInterval(() => {
      const updatedTimeLeft = calculateTimeLeft(date);
      setTimeLeft(updatedTimeLeft);

      if (!updatedTimeLeft) {
        clearInterval(timer);
        onCountdownEnd();
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [isClient, date, onCountdownEnd]);

  if (!timeLeft || timeLeft?.total <= 0) {
    return null;
  }

  return (
    <div className={classes.timer}>
      <div className={classes.time}>
        {timeLeft.days}
        <span>{t("days")}</span>
      </div>
      <div className={classes.time}>
        {timeLeft.hours} <span>{t("hours")}</span>
      </div>
      <div className={classes.time}>
        {timeLeft.minutes} <span>{t("minutes")}</span>
      </div>
      <div className={classes.time}>
        {timeLeft.seconds} <span>{t("seconds")}</span>
      </div>
    </div>
  );
};

Countdown.propTypes = {
  date: string.isRequired,
  onCountdownEnd: func.isRequired,
};

export default Countdown;
