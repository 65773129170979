import { node, object } from "prop-types";
import { createContext, useCallback, useMemo, useState } from "react";
import useSWR from "swr";

import { fetchShopBasket } from "~lib/requests/shop/basket";

export const ShopBasketContext = createContext(null);

export const ShopBasketProvider = ({
  children,
  shopBasket: initialShopBasket,
}) => {
  const [loadRequested, setLoadRequested] = useState(false);
  const [shopBasket, setShopBasket] = useState(initialShopBasket);
  const { data: shopBasketData } = useSWR(
    !shopBasket && loadRequested ? "/shop/products" : null,
    async () => (await fetchShopBasket())?.data,
  );

  const requestLoad = useCallback(() => {
    setLoadRequested(true);
  }, []);

  const value = useMemo(
    () => ({
      shopBasket: shopBasket || shopBasketData,
      setShopBasket,
      requestLoad,
    }),
    [shopBasket, shopBasketData, requestLoad],
  );

  return (
    <ShopBasketContext.Provider value={value}>
      {children}
    </ShopBasketContext.Provider>
  );
};

ShopBasketProvider.propTypes = {
  children: node,
  shopBasket: object,
};
