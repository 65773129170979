import { number } from "prop-types";

import classes from "./ImagePlaceholder.module.scss";

/**
 * This component is temporarily made to prevent the site from crashing when an
 * image is missing. Long term solution is to require images in Prepr.
 * @param {Object} props
 * @param {number} props.height - The height of the placeholder
 * @returns {JSX.Element}
 */
const ImagePlaceholder = ({ height }) => {
  console.warn(
    "[components/ImagePlaceholder]: Image is missing, see IAMS-405.",
  );
  return (
    <div
      className={classes.image_placeholder}
      style={{ height: `${height}px` }}
    >
      Image missing
    </div>
  );
};

ImagePlaceholder.propTypes = {
  height: number.isRequired,
};

export default ImagePlaceholder;
