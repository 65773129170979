import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { func, instanceOf } from "prop-types";
import { useCallback, useEffect, useRef, useState } from "react";

import ColoredSentence from "~components/ColoredSentence";
import Check from "~public/icons/check.svg";
import Cross from "~public/icons/cross.svg";
import Globe from "~public/icons/globe.svg";

import classes from "./LocaleSelectDesktop.module.scss";

const LocaleSelect = ({ onOpen, localizationsMap }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { locale: currentLocale, locales, events } = useRouter();
  const backdropRef = useRef();
  const { t } = useTranslation("languages");

  const toggleModal = useCallback(() => {
    onOpen(!modalOpen);
    setModalOpen(!modalOpen);
  }, [modalOpen, onOpen]);

  useEffect(() => {
    const onClick = (event) => {
      if (event.target === backdropRef.current) {
        toggleModal();
      }
    };

    if (modalOpen) {
      window.addEventListener("click", onClick);
    }

    return () => {
      window.removeEventListener("click", onClick);
    };
  }, [modalOpen, toggleModal]);

  // Close modal when route change starts.
  useEffect(() => {
    const onRouteChangeStart = () => {
      if (modalOpen) {
        setModalOpen(false);
        onOpen(false);
      }
    };
    events.on("routeChangeStart", onRouteChangeStart);
    return () => {
      events.off("routeChangeStart", onRouteChangeStart);
    };
  }, [events, modalOpen, onOpen]);

  return (
    <div className={classes.locale_select}>
      <button onClick={toggleModal} className={classes.locale_select_button}>
        {t(currentLocale)}
        <Globe />
      </button>
      <div className={classes[`modal${modalOpen ? "_open" : ""}`]}>
        <div className={classes.modal_header}>
          <div>
            <span>
              <ColoredSentence>{t("selectYourLanguage")}</ColoredSentence>
            </span>
            {currentLocale !== "en" && <p>Select your language</p>}
          </div>
          <button onClick={toggleModal}>
            <Cross />
          </button>
        </div>
        <div className={classes.modal_body}>
          {locales.map((availableLocale) => (
            <div key={availableLocale} className={classes.locale_item}>
              <Link
                href={
                  localizationsMap.has(availableLocale)
                    ? localizationsMap.get(availableLocale)
                    : `/${availableLocale}`
                }
                locale={false}
                hrefLang={availableLocale}
                className={classes.locale_button}
              >
                {t(availableLocale)}
                {availableLocale === currentLocale && <Check />}
              </Link>
            </div>
          ))}
        </div>
      </div>
      <div
        ref={backdropRef}
        className={classes[`backdrop${modalOpen ? "_open" : ""}`]}
      />
    </div>
  );
};

LocaleSelect.propTypes = {
  onOpen: func,
  localizationsMap: instanceOf(Map),
};

export default LocaleSelect;
