import AngleUp from "~public/icons/fa/angle-up.svg";
import ArrowLeft from "~public/icons/fa/arrow-left.svg";
import ArrowRight from "~public/icons/fa/arrow-right.svg";
import Check from "~public/icons/fa/check.svg";
import Cross from "~public/icons/fa/cross.svg";
import Email from "~public/icons/fa/email.svg";
import Facebook from "~public/icons/fa/facebook.svg";
import Globe from "~public/icons/fa/globe.svg";
import Instagram from "~public/icons/fa/instagram.svg";
import Phone from "~public/icons/fa/phone.svg";
import Pinterest from "~public/icons/fa/pinterest.svg";
import Search from "~public/icons/fa/search.svg";
import TikTok from "~public/icons/fa/tiktok.svg";
import Trashcan from "~public/icons/fa/trashcan.svg";
import Twitter from "~public/icons/fa/twitter.svg";
import Youtube from "~public/icons/fa/youtube.svg";

const icons = {
  arrowRight: ArrowRight,
  arrowLeft: ArrowLeft,
  check: Check,
  cross: Cross,
  search: Search,
  email: Email,
  phone: Phone,
  twitter: Twitter,
  instagram: Instagram,
  tiktok: TikTok,
  pinterest: Pinterest,
  facebook: Facebook,
  youtube: Youtube,
  website: Globe,
  angleUp: AngleUp,
  trashcan: Trashcan,
};

export default icons;
