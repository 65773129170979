import { useEffect, useRef } from "react";

const useCookiebotLoader = (callback) => {
  const onCookieLoadCompleteRef = useRef(callback);

  useEffect(() => {
    onCookieLoadCompleteRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const onCookieLoadComplete = () => {
      if (typeof onCookieLoadCompleteRef.current === "function") {
        onCookieLoadCompleteRef.current();
      }
    };

    if (window.Cookiebot) {
      onCookieLoadComplete();
      return;
    }

    // Cookiebot does not have a onLoad callback (at least not in the way you would expect)
    // so we use an interval to check if the Cookiebot object is available
    const intervalId = setInterval(() => {
      if (window.Cookiebot) {
        clearInterval(intervalId);
        onCookieLoadComplete();
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);
};

export default useCookiebotLoader;
