import { string } from "prop-types";

import classnames from "~utils/classnames";

import classes from "./DuoColorText.module.scss";

const DuoColorText = ({ text, emphasis, emphasisClassName, className }) => {
  if (typeof text !== "string") {
    return null;
  }
  if (!emphasis) {
    return <span className={className}>{text}</span>;
  }
  const strings =
    text
      .split(new RegExp(`(${emphasis})`, "g")) // use regex so the "emphasis" is part of the result array
      .filter(Boolean)
      .map((part, index) => (
        <span
          key={index}
          className={
            emphasis === part
              ? classnames(classes.brand, emphasisClassName)
              : className
          }
        >
          {part}
        </span>
      )) ?? null;

  return <>{strings}</>;
};

DuoColorText.propTypes = {
  text: string.isRequired,
  emphasis: string,
  className: string,
  emphasisClassName: string,
};

export default DuoColorText;
