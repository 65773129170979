import Router from "next/router";
import { useEffect } from "react";

function saveScrollPosition(url) {
  const scrollPosition = { x: window.scrollX, y: window.scrollY };
  window.sessionStorage.setItem(url, JSON.stringify(scrollPosition));
}

function restoreScrollPosition(url) {
  const scrollPosition = JSON.parse(window.sessionStorage.getItem(url));
  if (scrollPosition) {
    window.scrollTo({
      top: scrollPosition.y,
      left: scrollPosition.x,
      behavior: "instant",
    });
  }
}

/**
 * @param {import("next").Router} router
 * @returns {void}
 */
export default function useScrollRestoration(router) {
  useEffect(() => {
    if (
      typeof window === "undefined" ||
      !("scrollRestoration" in window.history)
    ) {
      return;
    }

    let shouldScrollRestore = false;
    window.history.scrollRestoration = "manual";
    restoreScrollPosition(router.asPath);

    const onBeforeUnload = (event) => {
      saveScrollPosition(router.asPath);
      delete event["returnValue"];
    };

    const onRouteChangeStart = () => {
      saveScrollPosition(router.asPath);
    };

    const onRouteChangeComplete = (url) => {
      if (shouldScrollRestore) {
        shouldScrollRestore = false;
        restoreScrollPosition(url);
      }
    };

    window.addEventListener("beforeunload", onBeforeUnload);
    Router.events.on("routeChangeStart", onRouteChangeStart);
    Router.events.on("routeChangeComplete", onRouteChangeComplete);
    Router.beforePopState(() => {
      shouldScrollRestore = true;
      return true;
    });

    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
      Router.events.off("routeChangeStart", onRouteChangeStart);
      Router.events.off("routeChangeComplete", onRouteChangeComplete);
      Router.beforePopState(() => true);
    };
  }, [router]);
}
