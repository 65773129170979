import Link from "next/link";
import { useTranslation } from "next-i18next";
import { shape, string } from "prop-types";

import Button from "~components/Button";
import Icon from "~components/Icon";
import ImageComponent from "~components/ImageComponent";
import { ImageType } from "~types";

import classes from "./ExpertCard.module.scss";

export const ExpertCard = ({ title, image, slug }) => {
  const { t } = useTranslation();

  return (
    <div className={classes.expert}>
      <Link className={classes.wrapper} href={slug}>
        {image?.src && (
          <div className={classes.image_wrapper}>
            <ImageComponent src={image.src} alt="" width={300} height={350} />
          </div>
        )}
        <div className={classes.label}>
          <h2 className={classes.title}>{title}</h2>
          <Button
            variant="text"
            title={t("expertCardLabel")}
            size="small"
            startIcon={
              <Icon className={classes.icon} type="arrowRight" size={1.2} />
            }
            className={classes.button}
          >
            {t("expertCardLabel")}
          </Button>
        </div>
      </Link>
    </div>
  );
};

export const ExpertCardType = {
  title: string.isRequired,
  description: string,
  image: shape(ImageType).isRequired,
  slug: string.isRequired,
};

ExpertCard.propTypes = ExpertCardType;
