import { useRouter } from "next/router";
import { instanceOf } from "prop-types";

import formatDate from "~utils/formatDate";

import classes from "./DateTag.module.scss";

const DateTag = ({ eventDate }) => {
  const { locale } = useRouter();
  return (
    <div className={classes.event_datepart}>
      <div className={classes.event_day}>
        {formatDate(eventDate, locale, "dd")}
      </div>
      <div className={classes.event_monthyear}>
        {formatDate(eventDate, locale, "MMM yy").replace(" ", " '")}
      </div>
    </div>
  );
};

DateTag.propTypes = {
  eventDate: instanceOf(Date),
};

export default DateTag;
