const PRODUCT_DEFAULT_VAT_PERCENTAGE = 9;

const PRODUCT_TYPE = {
  CITY_CARD: "citycard",
  CANAL_CRUISE: "canalcruise",
};

module.exports = {
  PRODUCT_DEFAULT_VAT_PERCENTAGE,
  PRODUCT_TYPE,
};
