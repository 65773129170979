import { string } from "prop-types";

import classes from "./ColoredSentence.module.scss";

const ColoredSentence = ({ children }) => {
  const words = children.split(" ");
  const firstWord = words.shift();

  return (
    <>
      <span className={classes.text_black}>{firstWord} </span>
      <span className={classes.text_primary}>{words.join(" ")}</span>
    </>
  );
};

ColoredSentence.propTypes = {
  children: string,
};

export default ColoredSentence;
