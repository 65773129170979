/**
 * Creates a focus trap within a specified container element. A focus trap ensures that the focus
 * remains within the container, making sure that the user can't tab outside of it. Additionally,
 * it provides an option to specify an initial focus element and a callback function for the Escape key.
 *
 * @param {HTMLElement} container - The container element within which focus should be trapped.
 * @param {Object} [options={}] - Optional configuration for the focus trap.
 * @param {HTMLElement|null} [options.initialFocus=null] - An optional element to focus initially when the trap is activated.
 *                                                         If not provided, the first focusable element will be focused.
 * @param {Function|null} [options.onEscape=null] - An optional callback function that is called when the Escape key is pressed.
 *                                                  If not provided, pressing Escape will have no additional behavior.
 * @returns {Function} - Returns a function to deactivate the focus trap. This function should be called
 *                       when the focus trap is no longer needed (e.g., when closing a modal).
 *
 * @example
 * const deactivateFocusTrap = focusTrap(modalElement, {
 *   initialFocus: modalElement.querySelector('.initial-focus'),
 *   onEscape: () => closeModal()
 * });
 *
 * // Later, when you want to deactivate the trap:
 * deactivateFocusTrap();
 */
export const focusTrap = (
  container,
  { initialFocus = null, onEscape = null } = {},
) => {
  // Find all focusable elements within the container
  const focusableElements = container.querySelectorAll(
    'a, button, textarea, input, select, [tabindex]:not([tabindex="-1"])',
  );

  if (focusableElements.length === 0) {
    return;
  }

  const firstElement = focusableElements[0];
  const lastElement = focusableElements[focusableElements.length - 1];

  // Set initial focus
  if (initialFocus && container.contains(initialFocus)) {
    initialFocus.focus();
  } else {
    firstElement.focus();
  }

  const handleKeyDown = (event) => {
    switch (event.key) {
      case "Tab":
        if (event.shiftKey) {
          // Shift + Tab
          if (document.activeElement === firstElement) {
            event.preventDefault();
            lastElement.focus();
          }
        } else {
          // Tab
          if (document.activeElement === lastElement) {
            event.preventDefault();
            firstElement.focus();
          }
        }
        break;
      case "Escape":
        if (onEscape) {
          onEscape();
        }
        break;
      default:
        break;
    }
  };

  const handleFocus = (event) => {
    if (!container.contains(event.target)) {
      event.preventDefault();
      firstElement.focus();
    }
  };

  // Activate the focus trap
  document.addEventListener("keydown", handleKeyDown);
  document.addEventListener("focus", handleFocus, true);

  // Deactivate the focus trap
  return () => {
    document.removeEventListener("keydown", handleKeyDown);
    document.removeEventListener("focus", handleFocus, true);
  };
};
