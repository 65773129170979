/**
 * Weird netlify quirk: an array value comes through as a string with as delimiter ", "
 *
 * @param {Object.<string, string|string[]>} query
 * @returns {Object.<string, string|string[]>}
 */
export const normalizeQuery = (query) =>
  Object.entries(query).reduce((obj, [key, value]) => {
    if (typeof value === "string" && value.includes(", ")) {
      return {
        ...obj,
        [key]: value.split(", "),
      };
    }
    return {
      ...obj,
      [key]: value,
    };
  }, {});

export const urlSearchParamsFromQueryObject = (query) => {
  const urlSearchParams = new URLSearchParams();
  Object.entries(query).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((val) => urlSearchParams.append(key, val));
    } else if (value) {
      urlSearchParams.set(key, value);
    }
  });
  return urlSearchParams;
};

/**
 * @param {URLSearchParams} urlSearchParams
 * @returns {Object.<string, string|string[]>}
 */
export const objectFromUrlSearchParams = (urlSearchParams) => {
  const obj = {};
  urlSearchParams.forEach((value, key) => {
    if (obj[key]) {
      obj[key] = Array.isArray(obj[key])
        ? obj[key].concat([value])
        : [obj[key], value];
    } else {
      obj[key] = value;
    }
  });
  return obj;
};
