import { useEffect, useRef, useState } from "react";

/**
 * Custom hook to check if an element is in the viewport.
 *
 * @param {React.RefObject<HTMLElement>} ref - The ref of the element to observe.
 * @param {Object} [options] - Optional configuration object
 * @param {Element | Document | null} [options.root] - The element that is used as the viewport for checking visibility of the target
 * @param {number | number[]} [options.threshold] - Either a single number or an array of numbers between 0.0 and 1.0
 * @param {string} [options.rootMargin] - Margin around the root element, can be specified similar to CSS margin ('10px 20px 30px 40px')
 * @param {boolean} [options.observeOnce] - If true, stops observing after the element enters viewport once
 * @returns {boolean} - True if the element is in the viewport, false otherwise
 *
 * @example
 * import React, { useRef } from 'react';
 * import useInViewPort from './useInViewPort';
 *
 * function MyComponent() {
 *   const ref = useRef(null);
 *   const isInViewPort = useInViewPort(ref, {
 *     rootMargin: '50px',      // Triggers 50px before element enters viewport
 *     threshold: 0.5,          // Triggers when element is 50% visible
 *     observeOnce: true        // Only triggers once
 *   });
 *
 *   return (
 *     <div ref={ref}>
 *       {isInViewPort ? 'In viewport' : 'Not in viewport'}
 *     </div>
 *   );
 * }
 */
function useInViewPort(ref, options = {}) {
  const inViewportCount = useRef(0);
  const [inViewport, setInViewport] = useState(false);

  useEffect(() => {
    if (!ref.current) {
      setInViewport(false);
      return;
    }

    if (options.observeOnce && inViewportCount.current > 0) {
      return;
    }

    const observerOptions = {
      root: options?.root || null,
      threshold: options?.threshold || 0,
      rootMargin: options?.rootMargin || "0px",
    };

    const observer = new IntersectionObserver(([entry]) => {
      setInViewport(entry.isIntersecting);
      if (!entry.isIntersecting) {
        return;
      }

      inViewportCount.current += 1;

      if (options.observeOnce) {
        observer.unobserve(entry.target);
      }
    }, observerOptions);

    const currentRef = ref.current;
    observer.observe(currentRef);

    return () => {
      observer.unobserve(currentRef);
    };
  }, [options, ref]);

  return inViewport;
}

export default useInViewPort;
