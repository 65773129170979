import { number, string } from "prop-types";

import Link from "~components/Link";
import { toValidUrl } from "~utils/routing";

import classes from "./TextLink.module.scss";

const TextLink = ({ title, slug, tabIndex }) => (
  <div>
    <Link
      href={toValidUrl(`/${slug}`)}
      className={classes.link}
      tabIndex={tabIndex}
      aria-label={title}
    >
      {title}
    </Link>
  </div>
);

TextLink.propTypes = {
  title: string.isRequired,
  slug: string.isRequired,
  tabIndex: number,
};

export default TextLink;
