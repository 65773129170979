import { isSameDay } from "date-fns/isSameDay";

/**
 * Formats a range of event dates.
 * @param {Array} dates - Array of date objects with 'from' property.
 * @returns {Array} - Array containing start date and optionally end date.
 *
 * @example
 * // Single date
 * formatEventDateRange([{ from: '2024-05-15T10:00:00Z' }])
 * // Returns: [Wed May 15 2024 12:00:00 GMT+0200 (Central European Summer Time)]
 *
 * @example
 * // Multiple dates in the same day
 * formatEventDateRange([
 *   { from: '2024-05-15T10:00:00Z' },
 *   { from: '2024-05-15T15:00:00Z' }
 * ])
 * // Returns: [Wed May 15 2024 12:00:00 GMT+0200 (Central European Summer Time)]
 *
 * @example
 * // Multiple dates spanning different days
 * formatEventDateRange([
 *   { from: '2024-05-15T10:00:00Z' },
 *   { from: '2024-05-17T10:00:00Z' }
 * ])
 * // Returns: [Wed May 15 2024 12:00:00 GMT+0200 (Central European Summer Time), Fri May 17 2024 12:00:00 GMT+0200 (Central European Summer Time)]
 */

const formatEventDateRange = (dates = []) => {
  if (!Array.isArray(dates) || dates.length === 0) {
    return [];
  }

  if (dates.length === 1) {
    return [new Date(dates[0].from)];
  }

  const sortedDates = [...dates].sort(
    (a, b) => Date.parse(a.from) - Date.parse(b.from),
  );
  const startDate = new Date(sortedDates[0].from);
  const endDate = new Date(sortedDates[sortedDates.length - 1].from);

  return isSameDay(startDate, endDate) ? [startDate] : [startDate, endDate];
};

export default formatEventDateRange;
