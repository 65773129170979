import cookieConsentConfig from "~config/cookieConsent";

/**
 * @typedef {object} CookieConsent
 * @property {boolean} preferences
 * @property {boolean} statistics
 * @property {boolean} marketing
 */

/**
 *
 * @param {'ablyft'|'prepr'} name
 * @param {CookieConsent} cookieConsent
 * @returns {boolean}
 */
export function hasCookieConsent(name, cookieConsent = {}) {
  return !!cookieConsentConfig?.[name]?.some(
    (key) => cookieConsent?.[key] === true,
  );
}
