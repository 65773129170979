import { number } from "prop-types";
import { memo } from "react";

import classes from "./SkeletonCard.module.scss";

const SkeletonCard = ({ lines = 1 }) => (
  <div className={classes.SkeletonCard}>
    <div className={classes.image} />
    <div className={classes.lines}>
      {Array.from({ length: lines }, (_, i) => (
        <div key={i} />
      ))}
    </div>
  </div>
);

SkeletonCard.displayName = "SkeletonCard";

SkeletonCard.propTypes = {
  lines: number,
};

export default memo(SkeletonCard);
