import "~styles/globals.scss";

import { GoogleTagManager } from "@next/third-parties/google";
import axios from "axios";
import dynamic from "next/dynamic";
import Head from "next/head";
import { appWithTranslation } from "next-i18next";
import { arrayOf, bool, elementType, object, shape, string } from "prop-types";
import { useEffect, useMemo, useRef } from "react";

import localeStringConverter from "~common/localeStringConverter";
import Seo, { SeoPropTypes } from "~components/Seo";
import Watermark from "~components/Watermark";
import AccessibilityDataProvider from "~contexts/AccessibilityDataContext";
import { FavouriteItemsProvider } from "~contexts/FavouriteItemsContext";
import ReCaptchaProvider from "~contexts/GoogleReCaptchaContext";
import { NavigationProvider } from "~contexts/NavigationContext";
import { SearchParamsProvider } from "~contexts/SearchParamsContext";
import { SeoContext } from "~contexts/SeoContext";
import { ShopBasketProvider } from "~contexts/ShopBasketContext";
import { TagLabelsProvider } from "~contexts/TagLabelsContext";
import { ThemeProvider } from "~contexts/ThemeContext";
import { ToastProvider } from "~contexts/Toaster";
import useCookieConsent from "~hooks/useCookieConsent";
import useScrollRestoration from "~hooks/useScrollRestoration";
import { DomainType, SubFooterType } from "~types";
import { hasCookieConsent } from "~utils/cookieConsent";
import getVariable from "~utils/getVariable";
import { shopBasketPropType } from "~utils/propTypes/shop";
import { headScript as ablyftHeadScript } from "~utils/scripts/ablyft";
import { headScript as preprHeadScript } from "~utils/scripts/prepr";

// get google tag manager id from variables, if not set, GTM is disabled
const gtmId = getVariable("gtmId");

const NextNProgress = dynamic(() => import("nextjs-progressbar"), {
  ssr: false,
});
const Toaster = dynamic(() => import("~components/Toaster"), {
  ssr: false,
});
const MessageToaster = dynamic(() => import("~components/MessageToaster"), {
  ssr: false,
});

// preload fonts async so we have less time when the text is not visible yet
const preloads = (
  <>
    {process.env.NEXT_PUBLIC_ABLYFT_ENABLED === "1" && (
      <link rel="preconnect" href="https://cdn.ablyft.com" />
    )}
    <link
      rel="preload"
      href="/fonts/woff2/AmsterdamSans-ExtraBold.woff2"
      as="font"
      crossOrigin=""
      type="font/woff2"
    />
    <link
      rel="preload"
      href="/fonts/woff2/AmsterdamSans-Regular.woff2"
      as="font"
      crossOrigin=""
      type="font/woff2"
    />
    <link
      rel="preload"
      href="/fonts/woff2/AmsterdamSans-Bold.woff2"
      as="font"
      crossOrigin=""
      type="font/woff2"
    />
  </>
);

const sendPreprViewEvent = () => {
  // Timeout to ensure we send the new page.
  if (typeof window === "undefined" || !window.prepr) {
    return;
  }
  setTimeout(() => window.prepr("event", "View"), 1000);
};

const App = ({ Component, pageProps, router }) => {
  useScrollRestoration(router);

  const lastRouterPath = useRef(null);
  const {
    pageType,
    domains,
    directory,
    pages,
    currentDomain: defaultDomain,
    subFooter,
    csrfToken = null,
    breadcrumbs,
  } = pageProps;

  const cookieConsent = useCookieConsent();

  const gtmMetaElements = useMemo(() => {
    const metaTags = [];
    if (pageProps?.type) {
      metaTags.push(
        <meta key="prepr-type" name="prepr-type" content={pageProps.type} />,
      );
      const freeTags = pageProps?.[pageProps.type]?.freeTags;
      if (Array.isArray(freeTags)) {
        const tags = freeTags.map(({ body }) => body).filter(Boolean);
        if (tags.length > 0) {
          metaTags.push(
            <meta key="tags" name="tags" content={tags.join(",")} />,
          );
          // TODO: this is a test: can GTM handle json encoded arrays?
          // will be tested by IAMS
          // see: https://greenberry.atlassian.net/browse/IAMS-1151
          metaTags.push(
            <meta
              key="tags-array"
              name="tags-array"
              content={JSON.stringify(tags)}
            />,
          );
        }
      }
    }
    return metaTags;
  }, [pageProps]);

  useEffect(() => {
    if (csrfToken) {
      axios.defaults.headers.common["CSRF-Token"] = csrfToken;
    }
    if (router.locale) {
      axios.defaults.headers.common["X-Language-Locale"] =
        localeStringConverter.toLocale(router.locale);
    }
  }, [csrfToken, router.locale]);

  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }

    const onRouteChangeStart = () => {
      if (window.googletag?.pubadsReady) {
        window.googletag.pubads()?.clear();
      }
    };

    const onRouteChangeComplete = () => {
      // check if the url has actually changes since last time,
      // otherwise ablyft will clean up, but not re-init because the url didn't change

      const isInitialRoute = lastRouterPath.current === null;
      const currentRoute = window.location.href;

      if (lastRouterPath.current === currentRoute) {
        return;
      }

      if (window.ablyft) {
        // re-init ablyft after route change in nextjs
        // resetStates makes sure ablyft does not check if it's the same page/experiment
        window.ablyft.reInit({ resetStates: true });
      }

      // do not trigger prepr view event on initial load
      if (!isInitialRoute && window.prepr) {
        sendPreprViewEvent();
      }

      lastRouterPath.current = currentRoute;
    };

    router.events.on("routeChangeStart", onRouteChangeStart);
    router.events.on("routeChangeComplete", onRouteChangeComplete);

    // also run on initial load
    onRouteChangeComplete();

    return () => {
      router.events.off("routeChangeStart", onRouteChangeStart);
      router.events.off("routeChangeComplete", onRouteChangeComplete);
    };
  }, [router]);

  /**
   * Hotfix because it's friday 16:39.
   * Issue: overflow remained hidden after route change. This was the quickest
   * fix but a more durable solution should be implemented.
   */
  useEffect(() => {
    const onRouteChangeComplete = () => {
      document.body.style.overflow = "auto";
    };

    router.events.on("routeChangeComplete", onRouteChangeComplete);

    return () => {
      router.events.off("routeChangeComplete", onRouteChangeComplete);
    };
  }, [router.events]);
  /**
   * End hotfix
   */

  const getLayout = Component.getLayout || ((page) => page);

  const seoProps = useMemo(
    () => ({
      seo: pageProps.seo || null,
      localizations: pageProps.localizations || [],
    }),
    [pageProps.seo, pageProps.localizations],
  );

  // when cookies were already accepted prepr and ablyft will be loaded on the first page load (see _document.js)
  const shouldLoadPrepr =
    typeof window !== "undefined" && hasCookieConsent("prepr", cookieConsent);

  const shouldLoadAblyft = process.env.NEXT_PUBLIC_ABLYFT_ENABLED === "1";

  // for some pages we don't want to render any providers (e.g. admin pages)
  const disableProviders = pageProps.appProps?.disableProviders ?? false;
  if (disableProviders) {
    return getLayout(
      <>
        <Head>
          {preloads}
          {shouldLoadPrepr && preprHeadScript()}
          {shouldLoadAblyft && ablyftHeadScript()}
          {gtmMetaElements}
        </Head>
        <Component {...pageProps} />
      </>,
      pageProps,
    );
  }

  return (
    <>
      {seoProps.seo ? (
        <Seo localizations={seoProps.localizations} {...seoProps.seo}>
          {preloads}
          {shouldLoadPrepr && preprHeadScript()}
          {shouldLoadAblyft && ablyftHeadScript()}
          {gtmMetaElements}
        </Seo>
      ) : (
        <Head>
          {preloads}
          {shouldLoadPrepr && preprHeadScript()}
          {shouldLoadAblyft && ablyftHeadScript()}
          {gtmMetaElements}
          <title>I amsterdam</title>
        </Head>
      )}
      <NavigationProvider
        directory={directory}
        pages={pages}
        domains={domains}
        defaultDomain={defaultDomain}
        subFooter={subFooter}
        breadcrumbs={breadcrumbs}
      >
        <ReCaptchaProvider>
          <SearchParamsProvider debounceMs={100}>
            <FavouriteItemsProvider>
              <SeoContext.Provider value={seoProps}>
                <ShopBasketProvider shopBasket={pageProps.shopBasket ?? null}>
                  <TagLabelsProvider data={pageProps.tagLabels}>
                    <AccessibilityDataProvider
                      data={pageProps.accessibilityData}
                    >
                      <ThemeProvider>
                        <ToastProvider>
                          <NextNProgress
                            color="#FF0018"
                            height={2}
                            options={{ showSpinner: false }}
                          />
                          {getLayout(<Component {...pageProps} />, pageProps)}
                          <div id="modals" />
                          <Watermark pageType={pageType} />
                          <Toaster />
                          <MessageToaster />
                        </ToastProvider>
                      </ThemeProvider>
                    </AccessibilityDataProvider>
                  </TagLabelsProvider>
                </ShopBasketProvider>
              </SeoContext.Provider>
            </FavouriteItemsProvider>
          </SearchParamsProvider>
        </ReCaptchaProvider>
      </NavigationProvider>
      {gtmId && <GoogleTagManager gtmId={gtmId} />}
    </>
  );
};

App.propTypes = {
  Component: elementType.isRequired,
  pageProps: shape({
    seo: shape(SeoPropTypes),
    localizations: arrayOf(
      shape({
        locale: string,
        slug: string,
      }),
    ),
    domains: arrayOf(shape(DomainType)),
    directory: string,
    pages: arrayOf(string),
    currentDomain: shape(DomainType),
    subFooter: shape(SubFooterType),
    shopBasket: shopBasketPropType,
    appProps: shape({
      disableProviders: bool,
    }),
  }).isRequired,
  router: shape({
    locale: string,
    events: object,
  }).isRequired,
};

export default appWithTranslation(App);
