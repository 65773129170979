import { arrayOf, oneOf, shape, string } from "prop-types";

import Card from "~components/Cards";
import DuoColorText from "~components/DuoColorText";
import { ImageType, PreprDataType } from "~types";

import classes from "./FeaturedBlock.module.scss";

const FeaturedBlock = ({
  overline,
  title,
  titleEmphasis,
  featuredItems,
  preprData,
  subTitle,
}) => {
  const cardSize = featuredItems.length >= 3 ? "large" : "x-large";

  return (
    <section className={classes.container}>
      {overline && <p className={classes.caption}>{overline}</p>}
      {title && (
        <div className={classes.title_wrapper}>
          {title && (
            <h2 className={classes.title}>
              <DuoColorText text={title} emphasis={titleEmphasis} />
            </h2>
          )}
          {subTitle && <p className={classes.subtitle}>{subTitle}</p>}
        </div>
      )}
      <div className={classes.items}>
        {featuredItems.map((item) => {
          let image = item.image?.[0];
          if (!image && item.imageUrl) {
            image = {
              alt: "",
              src: item.imageUrl,
              objectFit: "cover",
            };
          }
          return (
            <div key={item.id} className={classes.item}>
              <Card
                type="link"
                size={cardSize}
                slug={`/${item.slug}`}
                image={image}
                imageSource="prepr"
                title={item.title}
                bottom={item.author?.[0]?.fullName}
                preprData={preprData}
              />
            </div>
          );
        })}
      </div>
    </section>
  );
};

export const FeaturedBlockType = {
  id: string,
  type: oneOf(["TopTasks", "ArticleOverviewBlock", "FeaturedBlock"]),
  overline: string,
  title: string,
  titleEmphasis: string,
  subTitle: string,
  preprData: shape(PreprDataType),
  featuredItems: arrayOf(
    shape({
      id: string,
      slug: string,
      title: string,
      image: arrayOf(shape(ImageType)),
      author: arrayOf(
        shape({
          fullName: string,
        }),
      ),
    }),
  ),
};

FeaturedBlock.propTypes = FeaturedBlockType;

export default FeaturedBlock;
