import dynamic from "next/dynamic";
import { oneOf, string } from "prop-types";
import { memo } from "react";

import { useTheme } from "~contexts/ThemeContext";
import classnames from "~utils/classnames";

import classes from "./Banner.module.scss";

const HeroDesktopSVG = dynamic(
  () => import("~public/images/750/banners/heroBannerDesktop.svg"),
  {
    ssr: false,
  },
);
const HeroMobileSVG = dynamic(
  () => import("~public/images/750/banners/heroBannerMobile.svg"),
  {
    ssr: false,
  },
);

const svgs = {
  heroDesktop: HeroDesktopSVG,
  heroMobile: HeroMobileSVG,
};

const classNamesMap = {
  heroDesktop: classes.hero_desktop,
  heroMobile: classes.hero_mobile,
};

const ThemeBanner = ({ type, className }) => {
  const { theme } = useTheme();

  // should not render (and load the image) if the theme is not 750
  if (theme !== "750") {
    return null;
  }

  const SVG = svgs[type];

  return SVG ? (
    <SVG className={classnames(className, classNamesMap[type])} />
  ) : null;
};

ThemeBanner.propTypes = {
  type: oneOf(["heroDesktop", "heroMobile"]).isRequired,
  className: string,
};

export default memo(ThemeBanner);
