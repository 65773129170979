import { node } from "prop-types";

import SkipLink from "~components/SkipLink";
import Footer from "~modules/Footer";
import Header from "~modules/Header";

const Layout = ({ children }) => (
  <>
    <SkipLink />
    <Header />
    <main id="main-content">{children}</main>
    <Footer />
  </>
);

Layout.propTypes = {
  children: node,
};

export default Layout;
