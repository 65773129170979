import { node } from "prop-types";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

function ReCaptchaProvider({ children }) {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
      scriptProps={{
        async: true,
        defer: true,
        appendTo: "body",
      }}
    >
      {children}
    </GoogleReCaptchaProvider>
  );
}

ReCaptchaProvider.displayName = "ReCaptchaProvider";

ReCaptchaProvider.propTypes = {
  children: node.isRequired,
};

export default ReCaptchaProvider;
