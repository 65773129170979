import Head from "next/head";
import { useRouter } from "next/router";
import { arrayOf, node, shape, string } from "prop-types";
import { memo } from "react";

import localeStringConverter from "~common/localeStringConverter";
import getVariable from "~utils/getVariable";
import { resolveUrl } from "~utils/routing";

const baseUrl = getVariable("baseUrl");

const defaultOgImage =
  "https://4tdjltzf4a01.b-cdn.net//fit9d1fs9c0-defaultheaderphoto.jpg";

const emptyArray = [];

const Seo = ({
  children,
  preprId,
  customerId,
  title,
  description,
  siteName = "I amsterdam",
  ogType = "website",
  ogImage = defaultOgImage,
  slug,
  twitterHandle = "@Iamsterdam",
  localizations = emptyArray,
  canonicalUrl,
  noIndex,
  noFollow,
}) => {
  const router = useRouter();
  const { locale: lang } = useRouter();
  const locale = localeStringConverter.toLocale(lang);
  const localePrefix = router.defaultLocale === router.locale ? "" : `/${lang}`;
  const ogUrl = slug
    ? `${baseUrl}${localePrefix}/${slug}`
    : `${baseUrl}${localePrefix}`;
  const url =
    canonicalUrl ||
    (slug ? `${baseUrl}${localePrefix}/${slug}` : `${baseUrl}${localePrefix}`);

  const englishVersion = localizations?.find(
    (localization) =>
      localization.locale === "en-GB" && typeof localization.slug === "string",
  );

  return (
    <Head>
      <title key="title">{title}</title>
      {description && <meta name="description" content={description} />}
      <meta key="og_type" property="og:type" content={ogType} />
      <meta key="og_title" property="og:title" content={title} />
      <meta
        key="og_description"
        property="og:description"
        content={description}
      />
      <meta key="og_locale" property="og:locale" content={locale} />
      <meta key="og_site_name" property="og:site_name" content={siteName} />
      <meta key="og_url" property="og:url" content={ogUrl} />
      <meta key="og_site_name" property="og:site_name" content={siteName} />
      <meta key="og_image" property="og:image" content={ogImage} />
      <meta
        key="og_image:alt"
        property="og:image:alt"
        content={`${title} | ${siteName}`}
      />
      <meta key="og_image:width" property="og:image:width" content="1200" />
      <meta key="og_image:height" property="og:image:height" content="630" />
      {(noIndex || noFollow) && (
        <meta
          name="robots"
          content={[noIndex && "noindex", noFollow && "nofollow"]
            .filter(Boolean)
            .join(", ")}
        />
      )}
      <meta
        key="twitter:card"
        name="twitter:card"
        content="summary_large_image"
      />
      <meta key="twitter:site" name="twitter:site" content={twitterHandle} />
      <meta
        key="twitter:creator"
        name="twitter:creator"
        content={twitterHandle}
      />
      <meta key="twitter:title" property="twitter:title" content={title} />
      <meta
        key="twitter:description"
        property="twitter:description"
        content={description}
      />
      {preprId && <meta property="prepr:id" content={preprId} />}
      {customerId && <meta property="prepr:customer-id" content={customerId} />}

      <link key="canonical" rel="canonical" href={url} />

      {englishVersion && (
        <link
          rel="alternate"
          href={`${baseUrl}${resolveUrl(`/${englishVersion.slug}`, {
            defaultLocale: router.defaultLocale,
            locale: "en",
          })}`}
          hrefLang="x-default"
        />
      )}

      {localizations.map((localization) => {
        const lang = localeStringConverter.toLang(localization.locale);
        if (
          typeof localization.slug === "undefined" ||
          localization.slug === null ||
          !localization.locale
        ) {
          // skip if no slug (sometimes we get archived pages in localizations)
          return null;
        }
        // if this page has translations in other pages, add alternate links
        return (
          <link
            key={`${localization.locale}-${localization.slug}`}
            rel="alternate"
            href={`${baseUrl}${resolveUrl(`/${localization.slug}`, {
              defaultLocale: router.defaultLocale,
              locale: lang,
            })}`}
            hrefLang={lang}
          />
        );
      })}
      <link rel="shortcut icon" href="/favicon.ico" />
      {children}
    </Head>
  );
};

export const SeoPropTypes = {
  preprId: string,
  customerId: string,
  title: string.isRequired,
  description: string.isRequired,
  siteName: string,
  ogType: string,
  ogImage: string,
  slug: string,
  twitterHandle: string,
};

Seo.propTypes = {
  children: node,
  localizations: arrayOf(
    shape({
      locale: string,
      slug: string,
    }),
  ),
  ...SeoPropTypes,
};

Seo.displayName = "Seo";

export default memo(Seo);
