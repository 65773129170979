const localeStrings = {
  ["en-GB"]: "en",
  ["nl-NL"]: "nl",
  ["de-DE"]: "de",
  ["es-ES"]: "es",
  ["pt-PT"]: "pt",
  ["it-IT"]: "it",
  ["fr-FR"]: "fr",
};

const langStrings = Object.keys(localeStrings).reduce(
  (acc, key) => ({ ...acc, [localeStrings[key]]: key }),
  {},
);

const toLocale = (lang) => {
  if (!langStrings[lang]) {
    console.warn(`${lang} is not a defined locale string!`);
    return lang;
  }
  return langStrings[lang];
};

const toLang = (locale) => {
  if (!localeStrings[locale]) {
    console.warn(`${locale} is not a defined language string!`);
    return locale;
  }
  return localeStrings[locale];
};

const localeStringConverter = {
  toLocale,
  toLang,
};

module.exports = localeStringConverter;
