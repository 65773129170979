import { arrayOf, number, shape, string } from "prop-types";

import ImageComponent from "~components/ImageComponent";
import classnames from "~utils/classnames";

import classes from "./PrizeListCard.module.scss";

const PrizeListCard = ({ title, cover, description }) => {
  return (
    <div className={classes.container}>
      <div data-animation-id="slideIn" className={classes.image_wrapper}>
        {cover?.length > 0 &&
          cover.map((image, index) => (
            <ImageComponent
              key={index}
              src={image.url}
              alt={image.description || ""}
              width={image.width}
              height={422}
              layout="fill"
            />
          ))}
      </div>
      <h3
        data-animation-id="slideIn"
        className={classnames(classes.text, classes.title)}
      >
        {title}
      </h3>
      <p
        data-animation-id="slideIn"
        className={classnames(classes.text, classes.description)}
      >
        {description}
      </p>
    </div>
  );
};

export const PrizeListCardType = {
  title: string.isRequired,
  description: string.isRequired,
  cover: arrayOf(
    shape({
      id: string.isRequired,
      url: string.isRequired,
      width: number,
      height: number,
      caption: string,
      description: string,
    }).isRequired,
  ),
};

PrizeListCard.propTypes = PrizeListCardType;

export default PrizeListCard;
