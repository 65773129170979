const variables = require("~config/variables");
const environments = require("~config/environments");

/**
 * @typedef { "environment" |
 *            "baseUrl" |
 *            "cardApiBaseUrl" |
 *            "cardApiBaseUrl" |
 *            "gtmId" |
 *            "recaptchaEnabled" |
 *            "importerBaseUrl" |
 *            "aws.s3BucketName" |
 *            "salesforce.instanceURL" |
 *            "salesforce.clientID" |
 *            "salesforce.clientSecret" |
 *            "salesforce.username" |
 *            "salesforce.password" |
 *            "salesforce.quizCampaignId" |
 *            "getro.networkId" |
 *            "getro.email" |
 *            "getro.password" } VariableKey
 */

/**
 * Retrieves a environment specific variable based on the config in
 * src/config/variables and src/config/environments.
 * @param {VariableKey} key
 * @returns {string} Environment specific variable
 * @example getVariable("preprModels.event");
 */
const getVariable = (key) => {
  const environment = process.env.NEXT_PUBLIC_ENVIRONMENT;
  if (!environment || !environments.includes(environment)) {
    throw new Error(
      `[utils/getVariable] Environment "${environment}" not defined in config.environments.js.`,
    );
  }

  if (key === "environment") {
    return environment;
  }

  const value = key.split(".").reduce((acc, key) => acc?.[key], variables);

  if (typeof value === "undefined") {
    throw new Error(`[utils/getVariable] No value found for key "${key}".`);
  }

  let result = value[environment];

  // use "development" variable as a fallback for "local" environment
  if (environment === "local" && typeof result === "undefined") {
    result = value.development;
  }

  if (typeof result === "undefined") {
    throw new Error(
      `[utils/getVariable] No value found for key "${key}" in "${environment}" environment.`,
    );
  }

  return result;
};

export default getVariable;
