import Link from "next/link";
import { bool, func, node, oneOf, oneOfType, string } from "prop-types";

import classnames from "~utils/classnames";
import { toValidUrl } from "~utils/routing";

import classes from "./Button.module.scss";

const getLinkTarget = (target, href) => {
  if (!target && href?.startsWith("mailto:")) {
    // users that use browser-based email clients probably want the link to open in a new window
    return "_blank";
  }
  return target;
};

/**
 * @typedef {Object} ButtonProps
 * @property {string} [title] - The title of the button
 * @property {React.ReactNode} [children] - The content of the button
 * @property {() => void} [onClick] - The click handler for the button
 * @property {false | string} [locale] - The locale for the button link
 * @property {"black" | "primary" | "secondary" | "white" | string} [color] - The color of the button
 * @property {"solid" | "outline" | "text"} [variant] - The variant of the button
 * @property {boolean} [fullWidth] - Whether the button should be full width
 * @property {"medium" | "small"} [size] - The size of the button
 * @property {string} [href] - The href for the link
 * @property {string} [target] - The target for the link
 * @property {"button" | "submit" | "reset"} [type] - The type of the button
 * @property {React.ReactNode} [endIcon] - The end icon for the button
 * @property {React.ReactNode} [startIcon] - The start icon for the button
 * @property {string} [className] - Additional class names for the button
 * @property {boolean} [isLoading] - Whether the button is in a loading state
 * @property {boolean} [disabled] - Whether the button is disabled
 * @property {boolean} [hasTheme] - Whether the button has a theme
 */

/**
 * @param {React.HTMLProps<HTMLButtonElement> & ButtonProps} props
 */

const Button = ({
  title = "",
  children,
  onClick,
  locale,
  color = "black",
  variant = "solid",
  fullWidth = false,
  size = "medium",
  href,
  target,
  type = "button",
  endIcon,
  startIcon,
  className,
  isLoading = false,
  disabled = false,
  hasTheme = false,
  ...props
}) => {
  const buttonClass = classnames([
    className,
    classes.button,
    classes[`button_${variant}__${color}`],
    classes[`button__${size}`],
    isLoading && classes.button__loading,
    fullWidth && classes.button__wide,
    hasTheme && classes.button__theme,
    href && classes.button_link,
  ]);

  const content = (
    <>
      {startIcon}
      {children}
      {endIcon}
    </>
  );

  return href ? (
    <Link
      href={toValidUrl(href)}
      onClick={onClick}
      className={buttonClass}
      title={title}
      target={getLinkTarget(target, href)}
      locale={locale}
      aria-disabled={disabled}
      tabIndex={disabled ? -1 : 0}
      {...props}
    >
      <span>{content}</span>
    </Link>
  ) : (
    <button
      title={title}
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={buttonClass}
      {...props}
    >
      <span>{content}</span>
    </button>
  );
};

Button.propTypes = {
  title: string,
  children: node,
  onClick: func,
  locale: oneOfType([bool, string]),
  color: oneOf(["black", "primary", "secondary", "white"]),
  variant: oneOf(["solid", "outline", "text"]),
  fullWidth: bool,
  size: oneOf(["medium", "small"]),
  href: string,
  target: string,
  type: oneOf(["button", "submit", "reset"]),
  endIcon: node,
  startIcon: node,
  className: string,
  isLoading: bool,
  disabled: bool,
  hasTheme: bool,
};

export default Button;
