import Accessibility from "~public/icons/accessibility.svg";
import BagShopping from "~public/icons/agenda_categories/bag_shopping.svg";
import Bed from "~public/icons/agenda_categories/bed.svg";
import Bicycle from "~public/icons/agenda_categories/bicycle.svg";
import BuildingColumns from "~public/icons/agenda_categories/building_columns.svg";
import BuildingMuseum from "~public/icons/agenda_categories/building_museum.svg";
import Buste from "~public/icons/agenda_categories/buste.svg";
import CircleParking from "~public/icons/agenda_categories/circle_parking.svg";
import FerrisWheel from "~public/icons/agenda_categories/ferris_wheel.svg";
import FlagPennant from "~public/icons/agenda_categories/flag_pennant.svg";
import Gallery from "~public/icons/agenda_categories/gallery.svg";
import Key from "~public/icons/agenda_categories/key.svg";
import MapLocation from "~public/icons/agenda_categories/map_location.svg";
import MasksTheater from "~public/icons/agenda_categories/masks_theater.svg";
import MicrophoneStand from "~public/icons/agenda_categories/microphone_stand.svg";
import MugSaucer from "~public/icons/agenda_categories/mug_saucer.svg";
import PartyHorn from "~public/icons/agenda_categories/party_horn.svg";
import PlateUtensils from "~public/icons/agenda_categories/plate_utensils.svg";
import Ship from "~public/icons/agenda_categories/ship.svg";
import Shop from "~public/icons/agenda_categories/shop.svg";
import Store from "~public/icons/agenda_categories/store.svg";
import TentDoublePeak from "~public/icons/agenda_categories/tent_double_peak.svg";
import Tree from "~public/icons/agenda_categories/tree.svg";
import Utensils from "~public/icons/agenda_categories/utensils.svg";
import Windmill from "~public/icons/agenda_categories/windmill.svg";
import ArrowDown from "~public/icons/arrow-down.svg";
import ArrowDownLight from "~public/icons/arrow-down-light.svg";
import ArrowRight from "~public/icons/arrow-right.svg";
import ArrowsMaximize from "~public/icons/arrows-maximize.svg";
import Calendar from "~public/icons/calendar.svg";
import Check from "~public/icons/check.svg";
import ChevronDown from "~public/icons/chevron-down.svg";
import ChevronLeft from "~public/icons/chevron-left.svg";
import ChevronRight from "~public/icons/chevron-right.svg";
import ChevronUp from "~public/icons/chevron-up.svg";
import Clock from "~public/icons/clock.svg";
import Copy from "~public/icons/copy.svg";
import CreditCard from "~public/icons/credit-card.svg";
import CreditCardLight from "~public/icons/credit-card-light.svg";
import Cross from "~public/icons/cross.svg";
import Crowd from "~public/icons/crowd.svg";
import EditSquare from "~public/icons/edit-square.svg";
import Email from "~public/icons/email.svg";
import ExternalLink from "~public/icons/external-link.svg";
import Balloon from "~public/icons/fa/balloon.svg";
import Filter from "~public/icons/filter.svg";
import Filters from "~public/icons/filters.svg";
import Gift from "~public/icons/gift.svg";
import HeartOutline from "~public/icons/heart.svg";
import HeartSolid from "~public/icons/heart-solid.svg";
import Home from "~public/icons/home.svg";
import House from "~public/icons/house.svg";
import InfoCircle from "~public/icons/info-circle.svg";
import Link from "~public/icons/link.svg";
import LocationNeedle from "~public/icons/location-needle.svg";
import LocationPin from "~public/icons/location-pin.svg";
import MapWithoutPin from "~public/icons/map.svg";
import Map from "~public/icons/mapWithPin.svg";
import Min from "~public/icons/min.svg";
import MobilePhone from "~public/icons/mobile-phone.svg";
import Pause from "~public/icons/pause.svg";
import Play from "~public/icons/play.svg";
import Plus from "~public/icons/plus.svg";
import ShakeHands from "~public/icons/shake-hands.svg";
import Share from "~public/icons/share.svg";
import Ticket from "~public/icons/ticket.svg";
import Train from "~public/icons/train.svg";
import Trash from "~public/icons/trash.svg";

const iconFiles = {
  arrowDown: ArrowDown,
  arrowDownLight: ArrowDownLight,
  arrowRight: ArrowRight,
  arrowsMaximize: ArrowsMaximize,
  calendar: Calendar,
  checkmark: Check,
  cross: Cross,
  crowd: Crowd,
  edit: EditSquare,
  filter: Filter,
  filters: Filters,
  home: Home,
  house: House,
  info: InfoCircle,
  locationNeedle: LocationNeedle,
  locationPin: LocationPin,
  minus: Min,
  mobilePhone: MobilePhone,
  play: Play,
  plus: Plus,
  shakeHands: ShakeHands,
  ticket: Ticket,
  train: Train,
  heartOutline: HeartOutline,
  heartSolid: HeartSolid,
  creditCard: CreditCard,
  trash: Trash,
  externalLink: ExternalLink,
  balloon: Balloon,
  map: Map,
  chevronLeft: ChevronLeft,
  chevronRight: ChevronRight,
  chevronDown: ChevronDown,
  chevronUp: ChevronUp,
  share: Share,
  clock: Clock,
  creditCardLight: CreditCardLight,
  link: Link,
  email: Email,
  mapWithoutPin: MapWithoutPin,
  pause: Pause,
  accessibility: Accessibility,
  copy: Copy,
  gift: Gift,
  building_museum: BuildingMuseum,
  bag_shopping: BagShopping,
  ferris_wheel: FerrisWheel,
  tree: Tree,
  circle_parking: CircleParking,
  flag_pennant: FlagPennant,
  bicycle: Bicycle,
  party_horn: PartyHorn,
  map_location: MapLocation,
  microphone_stand: MicrophoneStand,
  masks_theater: MasksTheater,
  tent_double_peak: TentDoublePeak,
  gallery: Gallery,
  utensils: Utensils,
  ship: Ship,
  store: Store,
  key: Key,
  buste: Buste,
  plate_utensils: PlateUtensils,
  windmill: Windmill,
  mug_saucer: MugSaucer,
  shop: Shop,
  bed: Bed,
  building_columns: BuildingColumns,
};

export const iconTypes = Object.keys(iconFiles);

export default iconFiles;
