import { normalizeText } from "~utils/utils";

/**
 * Checks if a certain tag or tags are available in the list of free tags.
 * @param {Array} tags - The list of tags to check.
 * @param {string|Array<string>} tagsToFind - The tag or tags to search for.
 * @returns {boolean} True if any of the tags are found, otherwise false.
 *
 * @example
 * const freeTags = [{ body: '750Amsterdam' }, { body: 'ExampleTag' }];
 * const tag = '750amsterdam';
 * containsTag(freeTags, tag); // true
 *
 * @example
 * const freeTags = [{ body: '750Amsterdam' }, { body: 'ExampleTag' }];
 * const tags = ['750amsterdam', 'exampletag'];
 * containsTag(freeTags, tags); // true
 */

const containsTag = (tags, tagsToFind) => {
  if (!Array.isArray(tags) || tags.length === 0 || !tagsToFind) {
    return false;
  }

  const tagsToFindArray = Array.isArray(tagsToFind) ? tagsToFind : [tagsToFind];
  const filteredTagsToFind = tagsToFindArray.filter((tag) => tag && tag.trim());

  if (filteredTagsToFind.length === 0) {
    return false;
  }

  return tags.some((item) => {
    const formattedBody = normalizeText(item?.body);
    return filteredTagsToFind.some((tag) => formattedBody.includes(tag));
  });
};

export default containsTag;
