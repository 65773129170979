import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { bool, func, node, number, shape, string } from "prop-types";
import { Children, useEffect, useRef } from "react";
import SwiperCore from "swiper";
import { Keyboard, Navigation, Pagination, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

SwiperCore.use([Navigation, Pagination, Scrollbar, Keyboard]);

const Carousel = ({
  children,
  slidesPerView = 1,
  spaceBetween = 24,
  slidesPerGroup = 1,
  initialSlide = 0,
  centeredSlides = false,
  pagination = false,
  loop = false,
  scrollBar,
  breakpoints,
  width,
  height,
  onActiveIndexChange,
  className,
  prevButtonRef,
  nextButtonRef,
}) => {
  const swiperRef = useRef(null);

  useEffect(() => {
    if (
      !prevButtonRef.current ||
      !nextButtonRef.current ||
      !swiperRef.current
    ) {
      return;
    }
    const swiperInstance = swiperRef.current.swiper;
    swiperInstance.params.navigation.prevEl = prevButtonRef.current;
    swiperInstance.params.navigation.nextEl = nextButtonRef.current;
    swiperInstance.navigation.init();
    swiperInstance.navigation.update();

    // Update the active index callback
    const handleSlideChange = () => {
      onActiveIndexChange?.(swiperInstance.activeIndex);
    };
    swiperInstance.on("slideChange", handleSlideChange);

    return () => {
      swiperInstance.off("slideChange", handleSlideChange);
    };
  }, [prevButtonRef, nextButtonRef, onActiveIndexChange]);

  return (
    <Swiper
      ref={swiperRef}
      slidesPerView={slidesPerView}
      spaceBetween={spaceBetween}
      slidesPerGroup={slidesPerGroup}
      centeredSlides={centeredSlides}
      initialSlide={initialSlide}
      loop={loop}
      width={width}
      height={height}
      breakpoints={breakpoints}
      pagination={pagination ? { clickable: true } : false}
      scrollbar={scrollBar}
      className={className}
      navigation={{
        prevEl: prevButtonRef?.current,
        nextEl: nextButtonRef?.current,
      }}
    >
      {Children.map(children, (child) => (
        <SwiperSlide>{child}</SwiperSlide>
      ))}
    </Swiper>
  );
};

const options = {
  slidesPerView: number,
  spaceBetween: number,
  initialSlide: number,
  slidesPerGroup: number,
  showNavigation: bool,
  centeredSlides: bool,
  pagination: bool,
  scrollBar: shape({
    draggable: bool,
    dragClass: string,
    horizontalClass: string,
  }),
  loop: bool,
  width: number,
  height: number,
  onActiveIndexChange: func,
};

Carousel.propTypes = {
  children: node.isRequired,
  breakpoints: shape(options),
  className: string,
  classNamePrev: string,
  classNameNext: string,
  ...options,
};

export default Carousel;
