import { bool, oneOf, string } from "prop-types";

import { SOCIAL_PLATFORM } from "~config/constants";
import Facebook from "~public/icons/facebook.svg";
import Globe from "~public/icons/globe.svg";
import Instagram from "~public/icons/instagram.svg";
import Pinterest from "~public/icons/pinterest.svg";
import Tiktok from "~public/icons/tiktok.svg";
import Twitter from "~public/icons/twitter.svg";
import Youtube from "~public/icons/youtube.svg";

import classes from "./SocialMedia.module.scss";

const icons = {
  [SOCIAL_PLATFORM.INSTAGRAM]: Instagram,
  [SOCIAL_PLATFORM.TIKTOK]: Tiktok,
  [SOCIAL_PLATFORM.PINTEREST]: Pinterest,
  [SOCIAL_PLATFORM.FACEBOOK]: Facebook,
  [SOCIAL_PLATFORM.TWITTER]: Twitter,
  [SOCIAL_PLATFORM.WEBSITE]: Globe,
  [SOCIAL_PLATFORM.YOUTUBE]: Youtube,
  [SOCIAL_PLATFORM.LINKEDIN]: Globe, // TODO: replace this with LinkedIn icon when design is updated
};

const SocialMedia = ({
  platform,
  handle,
  url,
  color = "black",
  icon = false,
}) => {
  if (!platform || !url || !icons[platform]) {
    return null;
  }
  const IconComponent = icons[platform];
  return (
    <a
      href={url}
      target="_blank"
      className={classes[`social_media_${color}`]}
      rel="noreferrer"
      title={platform}
    >
      <div className={classes.social_media_icon}>
        {<IconComponent focusable="false" />}
      </div>
      {!icon && handle && <div className={classes.handle}>{handle}</div>}
    </a>
  );
};

SocialMedia.propTypes = {
  platform: oneOf(Object.values(SOCIAL_PLATFORM)).isRequired,
  url: string.isRequired,
  handle: string,
  color: oneOf(["primary", "secondary", "black", "white"]),
  icon: bool,
};

export default SocialMedia;
