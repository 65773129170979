import parse from "html-react-parser";
import Image from "next/image";
import { bool, number, string } from "prop-types";
import { forwardRef } from "react";

import Link from "~components/Link";
import classnames from "~utils/classnames";

import classes from "./RichText.module.scss";

const options = {
  transform(node) {
    const { type, props } = node;
    switch (type) {
      case "h1":
      case "h2":
        return <h2 key={node?.key}>{props.children}</h2>;
      case "br":
        return;
      case "p":
        if (!props?.children) {
          return <br />;
        }

        return <p key={node?.key}>{props.children}</p>;
      case "a":
        if (!props?.href) {
          return <span key={node?.key}>{props.children}</span>;
        }

        return (
          <Link
            key={node?.key}
            href={props?.href}
            target={props.target}
            rel={props.rel}
            underline
          >
            {props.children}
          </Link>
        );

      case "image": {
        if (!props?.src) {
          console.log("No image src found");
          return null;
        }

        return (
          <div key={node?.key}>
            <Image src={props?.src} alt={props?.alt} fill />
          </div>
        );
      }

      default:
        return node;
    }
  },
};

/**
 * @typedef {Object} RichTextProps
 * @property {string} html - The HTML content to be parsed and rendered.
 * @property {number} [tabIndex] - The tab index for the component.
 * @property {boolean} [ariaHidden] - Whether the component should be hidden from screen readers.
 * @property {string} [className] - Additional CSS class names for the component.
 */

/**
 * RichText component for parsing and rendering HTML content.
 *
 * @type {React.ForwardRefExoticComponent<RichTextProps & React.RefAttributes<HTMLDivElement>>}
 */
const RichText = forwardRef(
  ({ html, tabIndex, ariaHidden, className }, ref) => {
    if (!html) {
      return null;
    }

    return (
      <div
        ref={ref}
        tabIndex={tabIndex}
        className={classnames(classes.content, className)}
        aria-hidden={ariaHidden}
      >
        {parse(html, options)}
      </div>
    );
  },
);

RichText.displayName = "RichText";
RichText.propTypes = {
  html: string.isRequired,
  tabIndex: number,
  ariaHidden: bool,
  className: string,
};

export default RichText;
