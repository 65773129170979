import { useEffect, useState } from "react";

import useCookiebotLoader from "./useCookiebotLoader";

/**
 * @typedef {object} CookieConsent
 * @property {boolean} preferences
 * @property {boolean} statistics
 * @property {boolean} marketing
 */

const defaultConsent = {
  preferences: false,
  statistics: false,
  marketing: false,
};

function getCookieConsent() {
  if (typeof window === "undefined" || !window.Cookiebot?.consent) {
    return defaultConsent;
  }
  return {
    preferences: !!window.Cookiebot.consent.preferences,
    statistics: !!window.Cookiebot.consent.statistics,
    marketing: !!window.Cookiebot.consent.marketing,
  };
}

/**
 * @returns {CookieConsent}
 */
const useCookieConsent = () => {
  const [cookieConsent, setCookieConsent] = useState(getCookieConsent);

  useCookiebotLoader(() => {
    setCookieConsent(getCookieConsent());
  });

  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }

    setCookieConsent(getCookieConsent());

    const onCookieConsentChange = () => {
      setCookieConsent(getCookieConsent());
    };

    // triggered when existing cookie consent is loaded use submits cookie consent
    window.addEventListener("CookiebotOnConsentReady", onCookieConsentChange);

    return () => {
      window.removeEventListener(
        "CookiebotOnConsentReady",
        onCookieConsentChange,
      );
    };
  }, []);

  return cookieConsent;
};

export default useCookieConsent;
