import { oneOf } from "prop-types";
import { memo } from "react";

import PersonCard from "~components/PersonCard";

import BaseCard from "./BaseCard";
import EventCard from "./EventCard";
import LinkCard from "./LinkCard";
import LocationCard from "./LocationCard";
import SkeletonCard from "./SkeletonCard";
import VenueCard from "./VenueCard";

/**
 * @typedef {Object} CardProps
 * @property {string} type - The type of card to render.
 */

/**
 * Card component that renders different types of cards based on the type prop.
 * @param {CardProps & Record<string, any>} props - Props for the Card component.
 * @returns {JSX.Element|null} The appropriate card component.
 */

const Card = (props) => {
  switch (props.type) {
    case "skeleton":
      return <SkeletonCard {...props} />;
    case "basic":
      return <BaseCard {...props} />;
    case "link":
      return <LinkCard {...props} />;
    case "Event":
      return <EventCard {...props} />;
    case "Location":
      return <LocationCard {...props} />;
    case "Venue":
      return <VenueCard {...props} />;
    case "Author":
    case "Acquisitor":
      return <PersonCard {...props} />;
    default:
      return null;
  }
};

Card.propTypes = {
  type: oneOf([
    "skeleton",
    "basic",
    "Event",
    "Location",
    "Venue",
    "link",
    "Author",
    "Acquisitor",
  ]),
};

export default memo(Card);
