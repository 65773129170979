/**
 * Resolves the URL for a domain object
 *
 * @param {{ landingPage?: Array<{ slug: string }> }} domain
 * @returns
 */
export default function resolveDomainUrl(domain) {
  const landingPage = domain?.landingPage?.[0];
  if (!landingPage) {
    return "/";
  }
  return `/${landingPage.slug}`;
}
