import dynamic from "next/dynamic";
import { number, shape, string } from "prop-types";

import ThemeBanner from "~components/750/banner";
import DuoColorText from "~components/DuoColorText";
import ImageComponent from "~components/ImageComponent";
import { useTheme } from "~contexts/ThemeContext";
import { ImageType } from "~types";

import classes from "./HomepageHero.module.scss";

const MouseTrailWithConfetti = dynamic(() => import("~components/MouseTrail"), {
  ssr: false,
});

const HomepageHero = ({
  title,
  titleEmphasis,
  intro,
  heroImage,
  imageWidth = 628,
  imageHeight = 628,
}) => {
  const { theme } = useTheme();

  return (
    <div className={classes.hero} id="hero">
      {theme === "750" && (
        <MouseTrailWithConfetti elementQuerySelector="#hero" />
      )}
      {/* //TODO: remove after 750 banner is removed */}
      <ThemeBanner type="heroDesktop" className={classes.desktop_banner} />
      <ThemeBanner type="heroMobile" className={classes.mobile_banner} />
      <div className={classes.container}>
        <div className={classes.grid}>
          {heroImage && (
            <div className={classes.image_column}>
              <div className={classes.image_background} />
              <div className={classes.image_wrapper}>
                <ImageComponent
                  {...heroImage}
                  key={heroImage.src}
                  priority
                  width={imageWidth}
                  height={imageHeight}
                  placeholder="blur"
                  sizes="(max-width: 768px) 100vw, 50vw"
                />
              </div>
            </div>
          )}
          <div className={classes.text_column}>
            <h1 className={classes.title}>
              <DuoColorText
                emphasisClassName={classes.emphasis}
                text={title}
                emphasis={titleEmphasis}
              />
            </h1>
            <p className={classes.intro_text}>{intro}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

HomepageHero.propTypes = {
  title: string.isRequired,
  titleEmphasis: string,
  intro: string,
  heroImage: shape(ImageType),
  imageWidth: number,
  imageHeight: number,
};

export default HomepageHero;
