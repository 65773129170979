import { arrayOf, shape, string } from "prop-types";

import DateTag from "./DateTag";
import classes from "./EventDate.module.scss";

const EventDate = ({ dates }) => {
  if (!Array.isArray(dates) || dates.length === 0) {
    return null;
  }

  const isSingularDate = dates.length === 1;

  const singularDate = (
    <div className={classes.event_date}>
      <DateTag eventDate={dates[0]} />
    </div>
  );

  const multipleDates = (
    <div className={classes.event_date}>
      <DateTag eventDate={dates[0]} />
      <span>-</span>
      <DateTag eventDate={dates[dates.length - 1]} />
    </div>
  );

  return isSingularDate ? singularDate : multipleDates;
};

EventDate.propTypes = {
  dates: arrayOf(
    shape({
      from: string,
      until: string,
    }),
  ),
};

export default EventDate;
