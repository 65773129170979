const externalUrlPrefixes = ["#", "http", "mailto:", "tel:"];

export const toValidUrl = (url) => {
  if (!url || typeof url !== "string") {
    return "";
  }

  if (
    !url.startsWith("/") &&
    externalUrlPrefixes.every((prefix) => !url.startsWith(prefix))
  ) {
    // if the url is not external, make sure it starts with a slash
    return `/${url}`;
  }
  // make sure url does not start with multiple slashes
  return url.replace(/^\/+/, "/");
};

export const resolveUrl = (path, { locale, defaultLocale }) => {
  if (!locale || locale === defaultLocale) {
    return path;
  }
  if (!path || path === "/") {
    return `/${locale}`;
  }
  return `/${locale}/${path.replace(/^\/+/, "")}`;
};
