import { bool, object, string } from "prop-types";

import Link from "~components/Link";
import classnames from "~utils/classnames";
import { toValidUrl } from "~utils/routing";

import HighlightLink from "../../HighlightLink";
import TextLink from "../../TextLink";
import classes from "../DesktopMenu.module.scss";

const SubMenu = ({ list, isActive = false, submenuDataId }) => {
  const url = toValidUrl(list.slug);
  const NavElement = url ? Link : "div";

  return (
    <nav
      aria-label={list.title}
      className={classnames(
        classes.nav_item,
        isActive && classes.nav_item_active,
      )}
    >
      <div>
        <NavElement
          href={url || undefined}
          className={classes.nav_itemtitle}
          tabIndex={0}
          role={!url ? "button" : undefined}
          aria-disabled={!url ? "true" : undefined}
          data-submenu-id={submenuDataId}
        >
          {list.title}
        </NavElement>
      </div>
      {list.items.map((listItem) =>
        list.showAsHighlights ? (
          <HighlightLink
            key={listItem.id}
            title={listItem.shortTitle || listItem.title}
            slug={listItem.slug}
            image={listItem?.image?.[0]}
            tabIndex={0}
          />
        ) : (
          <TextLink
            key={listItem.id}
            title={listItem.title}
            slug={listItem.slug}
            tabIndex={0}
          />
        ),
      )}
      {list.externalLinks?.map((externalLink) => (
        <div key={externalLink.id}>
          <Link
            href={externalLink.url}
            rel="noopener noreferrer"
            target="_blank"
            external
            tabIndex={0}
          >
            {externalLink.label}
          </Link>
        </div>
      ))}
    </nav>
  );
};

SubMenu.propTypes = {
  list: object.isRequired,
  isActive: bool,
  submenuDataId: string,
};

export default SubMenu;
