import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { instanceOf } from "prop-types";

import Globe from "~public/icons/globe.svg";

import classes from "./LocaleSelectMobile.module.scss";

const LocaleSelectMobile = ({ localizationsMap }) => {
  const { locale: currentLocale = "en", locales = [], push } = useRouter();
  const { t } = useTranslation("languages");

  const handleChange = (event) => {
    if (!localizationsMap) {
      return;
    }
    const selectedLocale = event.target.value;
    const targetLocale = localizationsMap.get(selectedLocale) || `/`;
    push(targetLocale, "", { locale: selectedLocale });
  };

  return (
    <label className={classes.select} aria-label={t("selectYourLanguage")}>
      <Globe aria-label={t("selectYourLanguage")} />
      <select
        onChange={handleChange}
        value={currentLocale}
        aria-label={t("selectYourLanguage")}
      >
        {locales.length > 0 ? (
          locales.map((locale) => (
            <option key={locale} value={locale}>
              {t(locale)}
            </option>
          ))
        ) : (
          <option value="en">English</option>
        )}
      </select>
    </label>
  );
};

LocaleSelectMobile.propTypes = {
  localizationsMap: instanceOf(Map).isRequired,
};

export default LocaleSelectMobile;
