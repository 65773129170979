import { useTranslation } from "next-i18next";

import Icon from "~components/Icon";
import { FreeTagsType } from "~types";

import classes from "./Card.module.scss";

const CityCardTags = ({ freeTags }) => {
  const { t } = useTranslation();

  if (!freeTags) {
    return null;
  }

  return freeTags.map(({ body }) => {
    switch (body) {
      case "freecard":
        return (
          <div key="freecard" className={classes.info}>
            <Icon type="creditCard" />
            {t("freeWithThe")} I amsterdam City Card
          </div>
        );
      case "discountcard":
        return (
          <div key="discountcard" className={classes.info}>
            <Icon type="creditCard" />
            {t("discountWithThe")} I amsterdam City Card
          </div>
        );
      case "giftcard":
        return (
          <div key="giftcard" className={classes.info}>
            <Icon type="creditCard" />
            {t("giftWithThe")} I amsterdam City Card
          </div>
        );
      default:
        return;
    }
  });
};

CityCardTags.propTypes = {
  freeTags: FreeTagsType,
};

export default CityCardTags;
