import { arrayOf, node, oneOf, oneOfType, shape, string } from "prop-types";
import { useCallback, useEffect, useRef, useState } from "react";

import RichText from "~modules/RichText";
import ChevronDown from "~public/icons/chevron-down.svg";
import classnames from "~utils/classnames";

import classes from "./Accordion.module.scss";

const generateId = (prefix, text) =>
  `${prefix}-${text.replace(/\s+/g, "-").toLowerCase()}`;

const Bellow = ({ title, content, type }) => {
  const bodyRef = useRef(null);
  const [open, setOpen] = useState(false);

  const headerId = generateId("accordion-header", title);
  const contentId = generateId("accordion-content", title);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleKeyDown = (e) => {
    if (!["Enter", " "].includes(e.key)) {
      return;
    }

    e.preventDefault();
    handleToggle();
  };

  const adjustHeight = useCallback((isOpen) => {
    if (!bodyRef.current) {
      return;
    }
    bodyRef.current.style.height = isOpen
      ? `${bodyRef.current.scrollHeight}px`
      : "0px";

    // Manage focusability
    const focusableElements = bodyRef.current.querySelectorAll(
      "a, button, input, textarea, select, [tabindex]",
    );
    focusableElements.forEach((el) => {
      el.tabIndex = isOpen ? 0 : -1;
    });
  }, []);

  useEffect(() => {
    adjustHeight(open);
  }, [adjustHeight, open]);

  useEffect(() => {
    const handleResize = () => {
      if (!open) {
        return;
      }
      adjustHeight(open);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [adjustHeight, open]);

  return (
    <div
      className={classnames(
        classes[`bellow_${type}`],
        open && type === "card" && classes.bellow_card__active,
      )}
    >
      <div
        className={classes.bellow_header}
        onClick={handleToggle}
        role="button"
        tabIndex={0}
        aria-expanded={open}
        aria-controls={contentId}
        id={headerId}
        onKeyDown={handleKeyDown}
      >
        <h3 className={classes.bellow_header_title}>{title}</h3>
        <span
          className={classnames(classes.bellow_header_icon, {
            [classes.bellow_header_icon_open]: open,
          })}
        >
          <ChevronDown />
        </span>
      </div>
      <div
        role="region"
        aria-labelledby={headerId}
        id={contentId}
        ref={bodyRef}
        className={classnames(classes.bellow_body_wrapper, {
          [classes.bellow_body_wrapper_open]: open,
          [classes.bellow_body_wrapper_closed]: !open,
        })}
      >
        <div className={classes.bellow_body}>
          {typeof content === "string" ? (
            <RichText html={content} />
          ) : (
            content.map((item, index) => (
              <div key={`item-${index}`} className={classes.bellow_body_item}>
                {item}
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

Bellow.propTypes = {
  title: string.isRequired,
  content: oneOfType([arrayOf(node), string]).isRequired,
  type: oneOf(["card", "default"]),
};

const Accordion = ({ items, bellowType = "default" }) => (
  <div>
    {items.map((item) => (
      <Bellow key={item.title} {...item} type={bellowType} />
    ))}
  </div>
);

Accordion.propTypes = {
  items: arrayOf(shape(Bellow.propTypes)).isRequired,
  bellowType: oneOf(["card", "default"]),
};

export default Accordion;
