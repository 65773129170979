import NextLink from "next/link";
import { bool, func, node, number, string } from "prop-types";
import { forwardRef } from "react";

import classnames from "~utils/classnames";

import classes from "./Link.module.scss";

/**
 * @typedef {Object} LinkProps
 * @property {import('react').ReactNode} children
 * @property {string} href
 * @property {import("react").MouseEventHandler<HTMLAnchorElement>} [onClick]
 * @property {string} [className]
 * @property {string} [target]
 * @property {string} [rel]
 * @property {string} [referenceId]
 * @property {boolean} [underline]
 * @property {string} [title]
 * @property {boolean} [hover]
 * @property {number} [tabIndex]
 */

/**
 * @type {import("react").ForwardRefExoticComponent<LinkProps & import('react').RefAttributes<HTMLAnchorElement>>}
 */
const Link = forwardRef(
  (
    {
      children,
      href,
      className,
      target,
      rel,
      referenceId,
      underline = false,
      onClick = null,
      hover,
      title,
      tabIndex,
      ...props
    },
    ref,
  ) => {
    if (!href) {
      console.error(
        ["Link component requires an href prop"]
          .concat(referenceId ? [`(Reference ID: ${referenceId})`] : [])
          .join(" "),
      );
      return null;
    }
    const isExternal =
      href.startsWith("http") && !href.includes("iamsterdam.com");

    return isExternal ? (
      <a
        className={classnames(
          classes.link,
          underline && classes.underline,
          hover && classes.hover,
          classes.external,
          className,
        )}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        title={title}
        onClick={onClick}
        tabIndex={tabIndex}
        ref={ref}
        {...props}
      >
        <span>{children}</span>
      </a>
    ) : (
      <NextLink
        href={href}
        className={classnames(
          classes.link,
          underline && classes.underline,
          hover && classes.hover,
          className,
        )}
        target={target}
        rel={rel}
        title={title}
        onClick={onClick}
        tabIndex={tabIndex}
        ref={ref}
        {...props}
      >
        <span>{children}</span>
      </NextLink>
    );
  },
);

Link.displayName = "Link";
Link.propTypes = {
  children: node.isRequired,
  href: string.isRequired,
  onClick: func,
  className: string,
  target: string,
  rel: string,
  referenceId: string,
  underline: bool,
  title: string,
  hover: bool,
  tabIndex: number,
};

export default Link;
