import Image from "next/image";
import { useTranslation } from "next-i18next";
import { bool, number, oneOf, string } from "prop-types";

import classes from "./ImageComponent.module.scss";

const ImageComponent = ({
  layout,
  src,
  blurDataURL,
  alt,
  width,
  height,
  sizes,
  priority,
  loading,
  author,
  copyright,
  hideAuthor,
  title,
  imageClassName,
}) => {
  const { t } = useTranslation();

  const imageProps = {
    src,
    sizes,
    placeholder: blurDataURL ? "blur" : "empty",
    blurDataURL,
    priority,
    className: imageClassName,
  };

  if (layout === "fill") {
    return (
      <>
        <div
          className={classes.feedfactory_container}
          style={{
            height: `${height}px`,
          }}
        >
          <Image
            {...imageProps}
            alt={alt ?? title ?? undefined}
            loading={loading}
            fill
          />
        </div>
        {copyright && !hideAuthor && (
          <div className={classes.author}>{t("imageFrom", { copyright })}</div>
        )}
      </>
    );
  }

  return (
    <>
      <div className={classes.prepr_container}>
        <Image
          {...imageProps}
          alt={alt}
          width={width}
          height={height}
          loading={loading}
        />
      </div>
      {author && !hideAuthor && (
        <div className={classes.author}>
          {t("imageFrom", { copyright: author })}
        </div>
      )}
    </>
  );
};

ImageComponent.propTypes = {
  layout: oneOf(["fill", "fixed", "intrinsic", "responsive"]),
  src: string.isRequired,
  blurDataURL: string,
  alt: string,
  width: number.isRequired,
  height: number.isRequired,
  sizes: string,
  author: string,
  copyright: string,
  priority: bool,
  loading: oneOf(["lazy", "eager"]),
  hideAuthor: bool,
  title: string,
  imageClassName: string,
};

export default ImageComponent;
