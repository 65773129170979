import { arrayOf, node, shape } from "prop-types";
import { createContext, useContext, useMemo } from "react";

import { AccessibilityCategoryType, AccessibilityFeatureType } from "~types";

const AccessibilityDataContext = createContext(null);

export const useAccessibilityData = () => useContext(AccessibilityDataContext);

/**
 * @typedef {Object} AccessibilityDataProviderProps
 * @property {Array<import("~types").AccessibilityFeatureType>} data.accessibilityFeatures
 * @property {Array<import("~types").AccessibilityCategoryType>} data.accessibilityCategories
 * @property {React.ReactNode} children
 */
export const AccessibilityDataProvider = ({ data, children }) => {
  const value = useMemo(
    () => ({
      features: data?.accessibilityFeatures,
      categories: data?.accessibilityCategories,
    }),
    [data],
  );

  return (
    <AccessibilityDataContext.Provider value={value}>
      {children}
    </AccessibilityDataContext.Provider>
  );
};

AccessibilityDataProvider.propTypes = {
  data: shape({
    features: arrayOf(shape(AccessibilityFeatureType)),
    categories: arrayOf(shape(AccessibilityCategoryType)),
  }).isRequired,
  children: node.isRequired,
};

export default AccessibilityDataProvider;
