import PropTypes from "prop-types";

import { MOLLIE_PAYMENT_STATUSES } from "~config/constants";
import { PRODUCT_TYPE } from "~config/shop";

export const productTypePropType = PropTypes.oneOf(Object.values(PRODUCT_TYPE));

export const productPropType = PropTypes.shape({
  productId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  quantity: PropTypes.number,
});

export const productGroupPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  deliveryOptions: PropTypes.string,
  productType: PropTypes.string,
  products: PropTypes.arrayOf(productPropType),
});

export const shopBasketDiscountPropType = PropTypes.shape({
  code: PropTypes.string.isRequired,
  description: PropTypes.string,
  productDiscounts: PropTypes.arrayOf(
    PropTypes.shape({
      productId: PropTypes.string.isRequired,
      euro: PropTypes.number,
      percent: PropTypes.number,
    }),
  ).isRequired,
});

export const shopBasketPropType = PropTypes.shape({
  products: PropTypes.arrayOf(productPropType).isRequired,
  customer: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    shippingMethod: PropTypes.string,
  }),
  orderId: PropTypes.string,
  paymentId: PropTypes.string,
  totalPrice: PropTypes.number.isRequired,
  productGroup: productGroupPropType,
  discounts: PropTypes.arrayOf(shopBasketDiscountPropType),
});

export const shopPaymentStatusPropType = PropTypes.oneOf(
  Object.values(MOLLIE_PAYMENT_STATUSES),
);
