import { arrayOf, shape, string } from "prop-types";

import Button from "~components/Button";
import DuoColorText from "~components/DuoColorText";
import ImageComponent from "~components/ImageComponent";
import ImagePlaceholder from "~components/ImagePlaceholder";
import { ImageType } from "~types";

import classes from "./CTABlock.module.scss";

const CTABlock = ({
  title,
  titleEmphasis,
  caption,
  usps,
  image,
  buttonText,
  slug,
}) => (
  <div className={classes.block}>
    <div className={classes.image}>
      {image?.length > 0 ? (
        <ImageComponent
          {...image[0]}
          alt=""
          width={530}
          height={555}
          hideAuthor
        />
      ) : (
        // Todo: Create placeholder image. IAMS-405
        <ImagePlaceholder height={555} />
      )}
    </div>
    <div className={classes.text}>
      <p className={classes.caption}>{caption}</p>
      <h2 className={classes.title}>
        <DuoColorText text={title} emphasis={titleEmphasis} />
      </h2>
      <div
        className={classes.usps}
        dangerouslySetInnerHTML={{ __html: usps }}
      />
      <Button
        variant="solid"
        color="secondary"
        href={slug}
        className={classes.button}
      >
        {buttonText}
      </Button>
    </div>
  </div>
);

export const CTABlockType = {
  id: string,
  title: string,
  titleEmphasis: string,
  caption: string,
  usps: string,
  image: arrayOf(shape(ImageType)),
  buttonText: string,
  slug: string,
};

CTABlock.propTypes = CTABlockType;

export default CTABlock;
