/**
 * Apply the result of each function in the array to the next function in the array.
 *
 * @param {Array.<Function>} fns
 * @param {*} to
 * @returns {*}
 */
export const applyFns = (fns, to) => fns.reduce((acc, fn) => fn(acc), to);

export const debounce = (callback, wait) => {
  let timeoutId = null;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      callback.apply(null, args);
    }, wait);
  };
};

export const clamp = (value, min, max) => Math.min(Math.max(value, min), max);

export const pick = (obj, keys) =>
  Object.fromEntries(Object.entries(obj).filter(([key]) => keys.includes(key)));

export const omit = (obj, keys) =>
  Object.fromEntries(
    Object.entries(obj).filter(([key]) => !keys.includes(key)),
  );

/**
 * Test if two arrays have at least one item in common.
 *
 * @param {Array<any>} array1
 * @param {Array<any>} array2
 * @returns {boolean}
 */
export const doArraysIntersect = (array1, array2) =>
  array1.some((item1) => array2.includes(item1));

/**
 * @template T - generic type input
 * @param {T|Array<T>} value
 * @returns {Array<T>}
 */
export const wrapArray = (value) => {
  if (typeof value === "undefined") {
    return [];
  }
  return Array.isArray(value) ? value : [value];
};

export const snakeCaseToCamelCase = (str) =>
  str
    .toLowerCase()
    .replace(/([-_][a-z0-9])/g, (group) =>
      group.toUpperCase().replace("-", "").replace("_", ""),
    );

export const filterObjectValues = (obj, filter) => {
  const filtered = {};
  Object.entries(obj).forEach(([key, value]) => {
    if (filter(value)) {
      filtered[key] = value;
    }
  });
  return filtered;
};

export const arrayOfNumbers = (length) => Array.from({ length }, (_, i) => i);

/**
 * Normalizes text by converting it to lowercase and removing all whitespace.
 * @param {string} text - The text to normalize.
 * @return {string} - The normalized text.
 */
export function normalizeText(text) {
  return text.toLowerCase().replace(/\s/g, "");
}

/**
 * Sanitizes a string by replacing non-alphanumeric characters with underscores.
 *
 * @param {string} str - The original name to sanitize
 * @returns {string} The sanitized name
 *
 * @example
 * sanitizeString("string with spaces and-dashes and_underscores");
 * // returns "string_with_spaces_and_dashes_and_underscores"
 *
 * sanitizeString("string with numbers 123 and special characters !@#$%^&*");
 * // returns "string_with_numbers_123_and_special_characters"
 */
export const sanitizeString = (str) => {
  if (typeof str !== "string") {
    return "";
  }
  return str
    .toLowerCase()
    .replace(/[^a-z0-9]/g, "_") // replace non-alphanumeric characters with underscores
    .replace(/_+/g, "_") // replace multiple underscores with a single underscore
    .replace(/^_|_$/g, ""); // remove leading and trailing underscores
};

/**
 * Converts a string from underscore to dot
 * @param {string} str - The string to convert
 * @returns {string} The converted string
 */
export const underscoreToDot = (str) => {
  return str.replace(/_/g, ".");
};
