import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { arrayOf, bool, shape, string } from "prop-types";
import { useContext } from "react";

import CityCardTags from "~components/Cards/CityCardTags";
import Chip from "~components/Chip";
import Icon from "~components/Icon";
import ImageComponent from "~components/ImageComponent";
import { themes } from "~constants/themes";
import TagLabelsContext from "~contexts/TagLabelsContext";
import getRegularTicket from "~lib/getRegularTicket";
import {
  EventAddressType,
  EventDateType,
  EventUrlType,
  FreeTagsType,
  ImageType,
  LocationShortType,
  PreprDataType,
  PricingType,
} from "~types";
import classnames from "~utils/classnames";
import containsTag from "~utils/containsTag";
import formatCardEventTimes from "~utils/formatCardEventTimes";
import formatEventDateRange from "~utils/formatEventDateRange";

import BaseCard from "../BaseCard";
import cardClasses from "../Card.module.scss";
import EventDate from "../EventDate";
import FavouriteButton from "../FavouriteButton";
import classes from "./EventCard.module.scss";

// Helper function to handle ticket info logic
const getTicketInfo = (soldOut, regularTicket, hasBooking, t) => {
  if (soldOut) {
    return {
      display: true,
      text: t("ticketsSoldOut"),
      className: cardClasses.soldout,
    };
  }

  if (regularTicket?.freeEntrance) {
    return { display: true, text: t("freeEntrance") };
  }

  if (hasBooking) {
    return { display: true, text: t("ticketsAvailable") };
  }

  return { display: false };
};

// Helper function to handle location data logic
const getLocationData = (location, eventAddress) => {
  if (location?.length) {
    return location;
  }
  if (eventAddress?.name) {
    return [eventAddress];
  }
  return [];
};

const EventCard = ({
  id,
  name,
  slug,
  images,
  eventDate,
  freeTags,
  location,
  eventAddress,
  urls,
  soldOut,
  preprData,
  pricing,
  className,
}) => {
  const { t } = useTranslation();
  const { locale } = useRouter();
  const { getTagLabel } = useContext(TagLabelsContext);

  const dates = formatEventDateRange(eventDate);
  const times = formatCardEventTimes(eventDate, locale, t("multipleTimes"));
  const tagLabel = getTagLabel(freeTags);
  const regularTicket = getRegularTicket(pricing);
  const is750 = containsTag(freeTags, themes.tags["750"]);
  const hasBooking = urls?.some(({ type }) => type === "Booking");

  // Get ticket info based on conditions
  const ticketInfo = getTicketInfo(soldOut, regularTicket, hasBooking, t);

  // Get location data
  const locationData = getLocationData(location, eventAddress);

  const hasMultipleAccessibilityFeatures = (accessibilityFeatures) =>
    accessibilityFeatures?.filter((feature) => feature.value).length > 1;

  return (
    <BaseCard
      slug={slug}
      image={images?.[0]}
      title={name}
      imageSource="feedfactory"
      className={className}
      topLeftComponent={
        is750 && (
          <ImageComponent
            src="/images/750/banners/cornerBanner.png"
            width={118}
            height={113}
            alt=""
          />
        )
      }
      topRightComponent={
        <FavouriteButton
          variant={is750 ? "theme" : "default"}
          itemId={id}
          itemTitle={name}
        />
      }
      bottomLeftComponent={<EventDate dates={dates} />}
      preprData={preprData}
    >
      {!!tagLabel?.length && (
        <div className={classes.tags}>
          {tagLabel
            .filter((tag) => tag.labelTitle)
            .map((tag) => {
              const is750Label =
                tag?.tagReference?.[0]?.slug.includes("750amsterdam");
              return (
                <Chip
                  key={tag.id}
                  color="black"
                  variant={is750Label ? "theme" : "default"}
                  className={classes.tag}
                >
                  {tag.labelTitle}
                </Chip>
              );
            })}
        </div>
      )}
      {eventDate?.length > 0 && (
        <div className={cardClasses.info}>
          <Icon type="clock" aria-hidden="true" />
          <span>{times}</span>
        </div>
      )}
      {locationData.length > 0 && (
        <>
          <div className={cardClasses.info}>
            <Icon type="locationPin" aria-hidden="true" />
            <span>
              {locationData.length > 1
                ? t("variousLocations")
                : locationData[0].name}
            </span>
          </div>
          {hasMultipleAccessibilityFeatures(
            locationData[0]?.accessibilityFeatures,
          ) && (
            <div className={cardClasses.info}>
              <Icon type="accessibility" />
              {t("accessibilityAvailable")}
            </div>
          )}
        </>
      )}
      {ticketInfo.display && (
        <div className={classnames(cardClasses.info, ticketInfo.className)}>
          <Icon type="ticket" aria-hidden="true" />
          <span>{ticketInfo.text}</span>
        </div>
      )}
      {freeTags && <CityCardTags freeTags={freeTags} />}
    </BaseCard>
  );
};

export const eventPropType = {
  id: string.isRequired,
  slug: string.isRequired,
  images: arrayOf(shape(ImageType)),
  name: string,
  eventDate: arrayOf(shape(EventDateType)),
  freeTags: FreeTagsType,
  location: arrayOf(shape(LocationShortType)),
  eventAddress: shape(EventAddressType),
  urls: arrayOf(shape(EventUrlType)),
  preprData: shape(PreprDataType),
  soldOut: bool,
  pricing: arrayOf(shape(PricingType)),
};

export const EventCardType = {
  ...eventPropType,
  className: string,
};

EventCard.propTypes = EventCardType;

export default EventCard;
