import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { useContext, useState } from "react";

import HeaderComponent from "~components/Header";
import MenuButton from "~components/MenuButton";
import { Toggle } from "~components/Toggle";
import { NavigationContext } from "~contexts/NavigationContext";
import {
  is750ThemeEnabled,
  PRIMARY_THEME,
  useTheme,
} from "~contexts/ThemeContext";
import resolveDomainUrl from "~lib/urls/resolveDomainUrl";
import LocaleSelect from "~modules/LocaleSelect";
import classnames from "~utils/classnames";

import DesktopMenu from "./components/DesktopMenu";
import MobileMenu from "./components/MobileMenu";
import classes from "./Header.module.scss";

const Header = () => {
  const [showDesktopMenu, setShowDesktopMenu] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [fadeContent, setFadeContent] = useState(false);
  const router = useRouter();
  const { domains, currentDomain, handleSelectDomain } =
    useContext(NavigationContext);

  const { theme, toggleTheme } = useTheme();
  const { t, i18n } = useTranslation();

  const toggleMobileMenu = () => setShowMobileMenu((prevState) => !prevState);

  const isDefault = theme === PRIMARY_THEME;

  const isThemeEnabled = is750ThemeEnabled(
    currentDomain,
    i18n.language,
    router.asPath,
  );

  return (
    <header className={classes.header_wrapper}>
      <div
        className={classnames(
          classes.backdrop,
          showDesktopMenu && classes.backdrop_open,
        )}
        role="presentation"
      />
      <div className={classes.header}>
        <div className={classes.domain_navigation}>
          <div className={classes.domain_wrapper}>
            {domains.map((domain) => (
              <Link
                key={domain.id}
                href={resolveDomainUrl(domain)}
                onClick={() => handleSelectDomain(domain.id)}
                className={classnames(
                  classes.domain_item,
                  currentDomain?.id === domain?.id &&
                    classes.domain_item_active,
                )}
              >
                {domain.title}
              </Link>
            ))}
            <div className={classes.domain_right}>
              {isThemeEnabled && (
                <Toggle
                  id="theme"
                  name="theme"
                  label={t("themeToggle")}
                  variant="theme"
                  checked={!isDefault}
                  onChange={toggleTheme}
                />
              )}
              <LocaleSelect
                type="desktop"
                onOpen={(value) => setFadeContent(value)}
              />
            </div>
          </div>
        </div>
        <HeaderComponent
          bottomLine
          subText={currentDomain?.subtext}
          leftContent={
            <div className={classes.toggle_mobilemenu}>
              <MenuButton open={showMobileMenu} toggle={toggleMobileMenu} />
            </div>
          }
          rightContent={
            <DesktopMenu
              showMenu={showDesktopMenu}
              setShowMenu={setShowDesktopMenu}
              fadeContent={fadeContent}
            />
          }
          showFavouritesButton={!!currentDomain.showFavouritesButton}
        />
      </div>
      {/** component with 'fixed' placement */}
      <MobileMenu
        showMenu={showMobileMenu}
        setShowMenu={setShowMobileMenu}
        id="menu-id"
      />
    </header>
  );
};

export default Header;
