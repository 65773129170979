import axios from "axios";

const baseUrl = "/api/shop";

export const fetchShopBasket = () => axios.get(`${baseUrl}/products`);

export const addOrUpdateProduct = (productId, quantity) =>
  axios.post(`${baseUrl}/products/${productId}`, {
    quantity,
  });

export const removeProduct = (productId) =>
  axios.delete(`${baseUrl}/products/${productId}`);

export const updateBasketCustomer = async ({
  firstName,
  lastName,
  email,
  locale,
  shippingMethod,
}) =>
  axios.put(`${baseUrl}/customer`, {
    firstName,
    lastName,
    email,
    shippingMethod,
    locale,
  });

export const checkoutBasket = async ({ returnUrl }) => {
  const urlSearchParams = new URLSearchParams({
    returnUrl,
  });
  return axios.post(
    `${baseUrl}/payment/checkout?${urlSearchParams.toString()}`,
  );
};
