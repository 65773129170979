import packageInfo from "package.json";
import { string } from "prop-types";

import classes from "./Watermark.module.scss";

const isProduction = process.env.NODE_ENV === "production";

const Watermark = ({ pageType }) => {
  if (isProduction) {
    return null;
  }
  return (
    <div className={classes.watermark}>
      v{packageInfo.version} @ {process.env.NODE_ENV}
      {pageType && ` - ${pageType}`}
    </div>
  );
};

Watermark.propTypes = {
  pageType: string,
};

export default Watermark;
