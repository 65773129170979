import { useRouter } from "next/router";
import { node, number } from "prop-types";
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  normalizeQuery,
  objectFromUrlSearchParams,
  urlSearchParamsFromQueryObject,
} from "~utils/searchParams";
import { omit, wrapArray } from "~utils/utils";

// "originalSlug" is appended by rewrites, so we know what the original slug was and we can
// load the global data for that page from prepr
const ignoreParams = ["originalSlug", "pages", "nextInternalLocale"];

const defaultOptions = {
  scrollToTop: false,
  replaceRoute: false,
};

const mergeOptions = (current, options) => ({
  replaceRoute: !!(current.replaceRoute || options.replaceRoute),
  scrollToTop: !!(current.scrollTotop || options.scrollTotop),
});

const shouldIgnoreParam = (param) => ignoreParams.includes(param);

const isRouteParam = (route, param) =>
  new RegExp(`\\[([.]{3})?${param}\\]`, "g").test(route);

const filterRouteParams = (query, route) =>
  Object.entries(query).reduce(
    (obj, [key, value]) =>
      isRouteParam(route, key) || shouldIgnoreParam(key)
        ? obj
        : Object.assign(obj, { [key]: value }),
    {},
  );

const SearchParamsContext = createContext({});

export default SearchParamsContext;

// TODO: this has to be checked if this bug still exists in nextjs
// nextjs bug: when using rewrites it also includes the query param name
// without dashes (-), using window.location.search instead fixes this problem
/**
 *
 * @param {import("next/router").NextRouter.query} query
 * @param {import("next/router").NextRouter.route} route
 * @returns
 */
function getUrlSearchParamObject(query, route) {
  if (typeof window === "undefined" || !window.location?.search) {
    return normalizeQuery(filterRouteParams(query, route));
  }
  return normalizeQuery(
    filterRouteParams(
      objectFromUrlSearchParams(new URLSearchParams(window.location.search)),
      route,
    ),
  );
}

export const SearchParamsProvider = ({ children, debounceMs = 100 }) => {
  const router = useRouter();
  const { route, asPath } = router;
  const [loadingContextIds, setLoadingContextIds] = useState([]);
  const [queryUpdates, setQueryUpdates] = useState(null);
  const [queryDeletes, setQueryDeletes] = useState(null);
  const changesApplied = useRef(false);
  const currentOptions = useRef(defaultOptions);

  const query = useMemo(
    () => getUrlSearchParamObject(router.query, router.route),
    [router.query, router.route],
  );

  const updatedQuery = useMemo(
    () =>
      omit(
        {
          ...query,
          ...(queryUpdates || {}),
        },
        queryDeletes || [],
      ),
    [query, queryUpdates, queryDeletes],
  );

  useEffect(() => {
    const handleRouteChange = () => {
      // make sure any changes were applied before the last route change
      // if not, apply them now
      if (changesApplied.current) {
        setQueryUpdates(null);
        setQueryDeletes(null);
        setLoadingContextIds([]);
        changesApplied.current = false;
        currentOptions.current = defaultOptions;
      }
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [queryUpdates, router.events]);

  useEffect(() => {
    // debounce options because
    // when params are updated rapidly, the router will redirect 2 times but
    // only the last one (or the first?) will be used
    if (!queryUpdates && !queryDeletes) {
      return;
    }
    const timeout = setTimeout(() => {
      const urlSearchParams = urlSearchParamsFromQueryObject(
        filterRouteParams(query, route),
      );

      if (queryUpdates) {
        Object.entries(queryUpdates).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            urlSearchParams.delete(key);
            value.forEach((val) => urlSearchParams.append(key, val));
          } else {
            urlSearchParams.set(key, value);
          }
        });
      }
      if (urlSearchParams.has("page") && urlSearchParams.get("page") === "1") {
        urlSearchParams.delete("page");
      }
      if (queryDeletes) {
        queryDeletes.forEach((key) => urlSearchParams.delete(key));
      }

      changesApplied.current = true;
      const routerMethod = currentOptions.current.replaceRoute
        ? "replace"
        : "push";

      let url = `${asPath.split("?")[0]}`;
      if (urlSearchParams.toString() !== "") {
        url = `${url}?${urlSearchParams.toString()}`;
      }

      router[routerMethod](url, undefined, {
        scroll: currentOptions.current.scrollToTop,
      });
    }, debounceMs);
    return () => clearTimeout(timeout);
  }, [asPath, query, router, queryUpdates, route, debounceMs, queryDeletes]);

  const setParams = useCallback((obj, options = {}, contextId = null) => {
    setQueryUpdates((state) => ({
      ...state,
      ...obj,
    }));
    if (contextId) {
      setLoadingContextIds((state) => [...state, contextId]);
    }
    currentOptions.current = mergeOptions(currentOptions.current, options);
  }, []);

  const removeParams = useCallback((keys, options = {}, contextId = null) => {
    setQueryDeletes((state) => [...(state || []), ...wrapArray(keys)]);
    if (contextId) {
      setLoadingContextIds((state) => [...state, contextId]);
    }
    currentOptions.current = mergeOptions(currentOptions.current, options);
  }, []);

  const value = useMemo(
    () => ({
      updatedQuery,
      setParams,
      removeParams,
      isLoading:
        (queryUpdates && Object.keys(queryUpdates).length > 0) ||
        (queryDeletes && queryDeletes.length > 0),
      contextIsLoading: (contextId) => loadingContextIds.includes(contextId),
    }),
    [
      loadingContextIds,
      queryDeletes,
      queryUpdates,
      removeParams,
      setParams,
      updatedQuery,
    ],
  );

  return (
    <SearchParamsContext.Provider value={value}>
      {children}
    </SearchParamsContext.Provider>
  );
};

SearchParamsProvider.propTypes = {
  children: node.isRequired,
  debounceMs: number,
};
