const SOCIAL_PLATFORM = {
  INSTAGRAM: "instagram",
  TIKTOK: "tiktok",
  PINTEREST: "pinterest",
  FACEBOOK: "facebook",
  TWITTER: "twitter",
  YOUTUBE: "youtube",
  WEBSITE: "website",
  LINKEDIN: "linkedin",
};

const MOLLIE_PAYMENT_STATUSES = {
  OPEN: "open",
  PENDING: "pending",
  AUTHORIZED: "authorized",
  PAID: "paid",
  CANCELED: "canceled",
  EXPIRED: "expired",
  FAILED: "failed",
};

const SHOP_SHIPPING_OPTIONS = {
  DIGITAL: "Digital",
  DOWNLOAD: "Download",
};

const CARD_API_STATUSES = {
  OPEN: "Open", // 200
  PAID: "Paid", // 1
  PENDING: "Pending", // 201
  AUTHORIZED: "Authorized", // 202
  EXPIRED: "Expired", // 203
  FAILED: "Failed", // 204
  CANCELED: "Canceled", // 255
};

const MOLLIE_PAYMENT_STATUSES_TO_CARDAPI_STATUS = {
  [MOLLIE_PAYMENT_STATUSES.OPEN]: CARD_API_STATUSES.OPEN,
  [MOLLIE_PAYMENT_STATUSES.PAID]: CARD_API_STATUSES.PAID,
  [MOLLIE_PAYMENT_STATUSES.PENDING]: CARD_API_STATUSES.PENDING,
  [MOLLIE_PAYMENT_STATUSES.AUTHORIZED]: CARD_API_STATUSES.AUTHORIZED,
  [MOLLIE_PAYMENT_STATUSES.EXPIRED]: CARD_API_STATUSES.EXPIRED,
  [MOLLIE_PAYMENT_STATUSES.FAILED]: CARD_API_STATUSES.FAILED,
  [MOLLIE_PAYMENT_STATUSES.CANCELED]: CARD_API_STATUSES.CANCELED,
};

const DATE_QUICK_FILTERS = {
  TODAY: "today",
  TOMORROW: "tomorrow",
  THIS_WEEKEND: "weekend",
};

const TIME_OF_DAY_QUICK_FILTERS = {
  MORNING: "morning",
  AFTERNOON: "afternoon",
  EVENING: "evening",
};

// these hours will be added to the date that's already corrected to the start of the day
const TIME_OF_DAY_HOURS = {
  MORNING: [4, 12],
  AFTERNOON: [12, 18],
  EVENING: [18, 29], // 29 = 24 + 5 === 5:00 the next day
};

module.exports = {
  SOCIAL_PLATFORM,
  SHOP_SHIPPING_OPTIONS,
  MOLLIE_PAYMENT_STATUSES,
  CARD_API_STATUSES,
  MOLLIE_PAYMENT_STATUSES_TO_CARDAPI_STATUS,
  DATE_QUICK_FILTERS,
  TIME_OF_DAY_HOURS,
  TIME_OF_DAY_QUICK_FILTERS,
};
