import {
  any,
  arrayOf,
  bool,
  instanceOf,
  number,
  oneOf,
  oneOfType,
  shape,
  string,
} from "prop-types";

import { SOCIAL_PLATFORM } from "~config/constants";

export const LinkType = {
  id: string.isRequired,
  title: string.isRequired,
  slug: string.isRequired,
};

export const AddressType = {
  id: string,
  type: string,
  street: string,
  houseNumber: string,
  zipcode: string,
  city: string,
};

export const IconFileType = {
  id: string.isRequired,
  type: string.isRequired,
  mimeType: string.isRequired,
  url: string.isRequired,
  name: string.isRequired,
};

export const ImageType = {
  src: string.isRequired,
  copyright: string,
  alt: string,
  title: string,
  width: number,
  height: number,
  layout: oneOf(["responsive", "fill", "fixed"]),
};

export const YoutubeVideoType = {
  src: string.isRequired,
  title: string,
  embedId: string,
};

export const AcquisitorType = {
  id: string.isRequired,
  name: string.isRequired,
  jobTitle: string.isRequired,
  phoneNumber: string,
  email: string,
  contactPage: arrayOf(
    shape({
      slug: string.isRequired,
    }),
  ),
  cc: string,
  avatar: shape({
    id: string,
    width: number,
    height: number,
    alt: string,
    url: string,
  }),
};

export const SocialMediaChannelTypes = {
  id: string.isRequired,
  handle: string,
  url: string.isRequired,
  platform: oneOf(Object.values(SOCIAL_PLATFORM)),
};

export const AuthorType = {
  id: string,
  fullName: string,
  firstName: string,
  lastName: string,
  description: string,
  socials: arrayOf(shape(SocialMediaChannelTypes)),
  avatar: shape({
    id: string,
    url: string,
  }),
};

export const NavigationLevelType = {
  id: string.isRequired,
  slug: string,
  title: string.isRequired,
  order: number.isRequired,
  showAsHighlights: bool,
};

export const DomainType = {
  id: string.isRequired,
  title: string.isRequired,
  subtext: string,
  order: number,
  landingPage: arrayOf(
    shape({
      title: string,
      slug: string,
    }),
  ),
  headerItems: arrayOf(shape(NavigationLevelType)),
  footerItems: arrayOf(
    shape({
      id: string,
      title: string,
      links: arrayOf(shape({ title: string, slug: string })),
    }),
  ),
  socials: arrayOf(shape(SocialMediaChannelTypes)),
};

export const SubFooterType = {
  copyright: number,
  links: arrayOf(shape(LinkType)),
};

export const VenueRoomType = {
  title: string,
  properties: arrayOf(
    shape({
      catid: string,
      value: string,
    }),
  ),
};

export const LongLatType = {
  latitude: number,
  longitude: number,
};

export const VenueType = {
  id: string.isRequired,
  name: string.isRequired,
  slug: string.isRequired,
  accessibilityInformation: string,
  accessibilityUrl: string,
  address: shape({
    city: string,
    house_number: string,
    street: string,
    zipcode: string,
  }),
  categories: arrayOf(
    shape({
      catid: string,
      value: string,
    }),
  ),
  distanceToAmsterdamCS: number,
  email: string,
  freeTags: arrayOf(string),
  image: shape({
    title: string,
    hlink: string,
    alt: string,
    copyright: string,
  }),
  blurDataURL: string,
  location: shape(LongLatType),
  shortDescription: string,
  longDescription: string,
  maxCapacity: number,
  phoneNumber: string,
  rooms: arrayOf(shape(VenueRoomType)),
  tags: arrayOf(string),
  urls: arrayOf(
    shape({
      website: string,
    }),
  ),
};

export const TagLabelType = shape({
  id: string,
  labelTitle: string,
  tagReference: arrayOf(
    shape({
      body: string,
    }),
  ),
});

export const TagGroupsType = shape({
  id: string,
  type: oneOf(["FilterGroup"]),
  name: string,
  tags: arrayOf(
    shape({
      id: string,
      type: oneOf(["Tag"]),
      body: string,
    }),
  ),
});

export const FreeTagsType = arrayOf(
  shape({
    body: string,
  }),
);

export const PreprDataType = {
  personalizationId: string,
  segmentIds: string,
};

export const EventUrlType = {
  type: oneOf(["Website", "TimeSlots", "Booking", "LastMinute", "SocialMedia"]),
  id: string,
  url: string,
};

export const PricingType = {
  id: string,
  type: string,
  rateFrom: number,
  rateUntil: number,
  extraInfo: any,
  freeEntrance: bool,
};

export const EventDateType = {
  from: oneOfType([string, instanceOf(Date)]),
  until: oneOfType([string, instanceOf(Date)]),
};

export const LocationType = {
  slug: string,
  name: string,
  address: shape(AddressType),
  coordinates: shape(LongLatType),
};

export const EventAddressType = {
  name: string,
};

export const AccessibilityFeaturesComponentType = {
  externalId: string,
  id: string,
  type: string,
  label: string,
  value: bool,
  text: string,
};

export const AccessibilityCategoryType = {
  externalId: string,
  id: string,
  type: string,
  icon: shape(IconFileType),
  title: string,
  description: string,
};

export const AccessibilityFeatureType = {
  externalId: string,
  id: string,
  type: string,
  icon: shape(IconFileType),
  title: string,
  category: shape(AccessibilityCategoryType),
  visibilityScope: string,
};

export const AccessibilityDataType = {
  features: arrayOf(shape(AccessibilityFeatureType)),
  categories: arrayOf(shape(AccessibilityCategoryType)),
};

export const LocationShortType = {
  name: string,
  accessibilityFeatures: arrayOf(shape(AccessibilityFeaturesComponentType)),
};
