import Image from "next/image";
import { useTranslation } from "next-i18next";
import { arrayOf, shape, string } from "prop-types";
import { useRef } from "react";

import Button from "~components/Button";
import { ExpertCard, ExpertCardType } from "~components/Cards/ExpertCard";
import Carousel from "~components/Carousel";
import IconButton from "~components/IconButton";
import { useTheme } from "~contexts/ThemeContext";
import classnames from "~utils/classnames";

import classes from "./ExpertCarousel.module.scss";

const CONFIG_CAROUSEL = {
  900: {
    slidesPerView: 2,
    width: null,
    centeredSlides: true,
  },
  1200: {
    slidesPerView: 3,
    width: null,
  },
};

const ExpertCarousel = ({ title, description, experts, link, label }) => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const { t } = useTranslation();
  const { theme } = useTheme();

  return (
    <section className={classes.icon_slider}>
      <div className={classes.content}>
        {title && <h2 className={classes.title}>{title}</h2>}
        {description && <p className={classes.description}>{description}</p>}
      </div>
      <div className={classes.carousel}>
        {!!experts?.length && (
          <Carousel
            showNavigation
            prevButtonRef={prevRef}
            nextButtonRef={nextRef}
            width={300}
            breakpoints={CONFIG_CAROUSEL}
            className={classes.slider}
          >
            {experts.map((expert) => (
              <ExpertCard key={expert.id} {...expert} />
            ))}
          </Carousel>
        )}
        <div className={classes.background}>
          <Image
            src="/images/750/banners/bannerCross.svg"
            width="3256"
            height="928"
            alt=""
          />
          <Image
            src="/images/750/banners/bannerGradient.svg"
            width="3283"
            height="660"
            alt=""
          />
        </div>
      </div>
      <div className={classes.controls}>
        <IconButton
          icon="arrowLeftThin"
          title={t("previous")}
          ref={prevRef}
          className={classnames(classes.prev_button)}
        />
        <IconButton
          icon="arrowRightThin"
          title={t("next")}
          ref={nextRef}
          className={classnames(classes.next_button)}
        />
      </div>
      {link?.[0]?.slug && label && (
        <Button
          color="secondary"
          href={link[0].slug}
          className={classes.cta}
          hasTheme={theme === "750"}
        >
          {label}
        </Button>
      )}
    </section>
  );
};

ExpertCarousel.propTypes = {
  title: string,
  description: string,
  experts: arrayOf(shape(ExpertCardType)),
  link: arrayOf(shape({ slug: string })),
  label: string,
};

export default ExpertCarousel;
