import { useRouter } from "next/router";
import { bool, func, shape, string } from "prop-types";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import Link from "~components/Link";
import { NavigationContext } from "~contexts/NavigationContext";
import resolveDomainUrl from "~lib/urls/resolveDomainUrl";
import LocaleSelect from "~modules/LocaleSelect";
import ShopCheckoutButton from "~modules/ShopCheckoutButton";
import ArrowRight from "~public/icons/arrow-right.svg";
import ChevronRight from "~public/icons/chevron-right.svg";
import classnames from "~utils/classnames";
import { focusTrap } from "~utils/focusTrap";

import classes from "./MobileMenu.module.scss";
import SubMenu from "./SubMenu";

const MenuButton = ({ item, onClick, isActive }) => (
  <button
    key={item.id}
    className={classes.main_navitem}
    onClick={() => onClick(item)}
    aria-expanded={isActive}
    aria-controls={`subMenu-${item.id}`}
  >
    {item.title}
    <ChevronRight />
  </button>
);

MenuButton.propTypes = {
  item: shape({
    id: string,
  }).isRequired,
  onClick: func.isRequired,
  isActive: bool,
};

const MobileMenu = ({ id, showMenu, setShowMenu }) => {
  const router = useRouter();
  const [hasBeenOpened, setHasBeenOpened] = useState(showMenu);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const { domains, currentDomain, handleSelectDomain } =
    useContext(NavigationContext);
  const headerItems = useMemo(
    () => currentDomain?.headerItems?.filter(Boolean) ?? [],
    [currentDomain],
  );

  const menuRef = useRef(null);
  const lastFocusedElement = useRef(null);
  const subMenuRef = useRef(null);

  const closeMenu = useCallback(() => setShowMenu(false), [setShowMenu]);

  useEffect(() => {
    if (!hasBeenOpened) {
      setHasBeenOpened(showMenu);
    }
  }, [showMenu, hasBeenOpened]);

  useEffect(() => {
    router.events.on("routeChangeStart", closeMenu);
    return () => {
      router.events.off("routeChangeStart", closeMenu);
    };
  }, [router, closeMenu]);

  // Focus trap for menu when active
  useEffect(() => {
    if (!showMenu) {
      return;
    }
    document.body.style.height = "100%";
    document.body.style.overflow = "hidden";

    const removeFocusTrap = focusTrap(menuRef.current, {
      onEscape: closeMenu,
    });

    return () => {
      removeFocusTrap();
      document.body.style.height = "auto";
      document.body.style.overflow = "auto";
    };
  }, [showMenu, closeMenu, activeSubMenu]);

  // Focus trap for submenu when active
  useEffect(() => {
    if (activeSubMenu && subMenuRef.current) {
      lastFocusedElement.current = document.activeElement;
      const removeSubMenuFocusTrap = focusTrap(subMenuRef.current, {
        onEscape: () => setActiveSubMenu(null),
      });

      return () => {
        removeSubMenuFocusTrap();
        if (lastFocusedElement.current) {
          lastFocusedElement.current.focus();
        }
      };
    }
  }, [activeSubMenu]);

  return (
    <div
      id={id}
      aria-hidden={!showMenu}
      className={classnames(
        classes.mobile_menu,
        showMenu && classes.mobile_menu_open,
      )}
      ref={menuRef}
    >
      {showMenu && (
        <>
          {!activeSubMenu ? (
            <div
              aria-hidden={!!activeSubMenu}
              className={classnames(
                classes.main_menu,
                activeSubMenu && classes.main_menu_closed,
              )}
            >
              {headerItems.map((item) => (
                <MenuButton
                  key={item.id}
                  item={item}
                  onClick={setActiveSubMenu}
                  isActive={activeSubMenu?.id === item.id}
                />
              ))}
              <div className={classes.main_navitem}>
                {!!currentDomain.showOrderButton && hasBeenOpened && (
                  <ShopCheckoutButton />
                )}
              </div>
              {domains.map((domain) => {
                const isActive = currentDomain?.id === domain?.id;
                return (
                  <Link
                    key={domain.id}
                    href={resolveDomainUrl(domain)}
                    onClick={() => handleSelectDomain(domain.id)}
                    className={classnames(
                      classes.domain_navitem,
                      isActive && classes.domain_navitem_active,
                    )}
                    aria-current={isActive ? "page" : undefined}
                  >
                    {domain.title} <ArrowRight />
                  </Link>
                );
              })}
              <div className={classes.domain_navitem}>
                <LocaleSelect type="mobile" />
              </div>
            </div>
          ) : (
            <div ref={subMenuRef}>
              <SubMenu
                activeSubMenu={activeSubMenu}
                headerItems={headerItems}
                onBackClick={() => setActiveSubMenu(null)}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

MobileMenu.propTypes = {
  id: string.isRequired,
  showMenu: bool.isRequired,
  setShowMenu: func.isRequired,
};

export default MobileMenu;
