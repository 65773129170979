import { bool, func, string } from "prop-types";

import classnames from "~utils/classnames";

import classes from "./MenuButton.module.scss";

const bars = [1, 2, 3, 4];

const classNames = {
  bar_1: classes.bar_1,
  bar_2: classes.bar_2,
  bar_3: classes.bar_3,
  bar_4: classes.bar_4,
};

const MenuButton = ({ open = false, toggle, title = "Menu" }) => (
  <button
    onClick={toggle}
    className={classes.menu}
    title={title}
    aria-expanded={open}
  >
    {bars.map((number) => (
      <span
        key={`bar-${number}`}
        className={classnames(
          classes.bar,
          classNames[`bar_${number}`],
          open && classes.open,
        )}
      />
    ))}
  </button>
);

MenuButton.propTypes = {
  open: bool,
  toggle: func,
  title: string,
};

export default MenuButton;
