import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";

import NewIcon from "~components/NewIcon";
import { searchRoutes } from "~config/routing";

import classes from "./SearchButton.module.scss";

const SearchButton = () => {
  const { locale } = useRouter();
  const { t } = useTranslation();

  const searchRoute = searchRoutes.find((route) => route.locale === locale);

  return (
    <Link
      className={classes.search_button}
      href={searchRoute.path}
      title={t("search")}
    >
      <NewIcon className={classes.search} type="search" />
    </Link>
  );
};

SearchButton.propTypes = {};

export default SearchButton;
