import { arrayOf, shape, string } from "prop-types";

import DuoColorText from "~components/DuoColorText";
import Icon from "~components/Icon";
import Link from "~components/Link";
import { toValidUrl } from "~utils/routing";

import classes from "./USPBlock.module.scss";

const USPBlock = ({ title, titleEmphasis, data }) => {
  if (!data?.length) {
    return null;
  }
  const { uspItems } = data[0];
  return (
    <section className={classes.block}>
      <div className={classes.border} />
      {title && (
        <h2 className={classes.title}>
          <DuoColorText text={title} emphasis={titleEmphasis} />
        </h2>
      )}
      <div className={classes.items}>
        {uspItems.map(({ label, link, icon }) => (
          <div key={label} className={classes.item}>
            <Icon
              type={icon}
              color="primary"
              removeIconDefaultSize
              className={classes.icon}
            />
            <div className={classes.content}>
              {link?.[0]?.slug ? (
                <Link href={toValidUrl(link[0].slug)}>
                  <span>{label}</span>
                </Link>
              ) : (
                <span>{label}</span>
              )}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export const USPBlockType = {
  uspItems: arrayOf(
    shape({
      label: string,
      icon: string, // todo: icon list
      link: arrayOf(
        shape({
          slug: string,
        }),
      ),
    }),
  ),
};

export const DynamicUSPBlockType = {
  title: string,
  titleEmphasis: string,
  uspBlocks: arrayOf(
    shape({
      uspItems: arrayOf(shape(USPBlockType)),
    }),
  ),
};

USPBlock.propTypes = {
  title: string,
  titleEmphasis: string,
  data: arrayOf(shape(USPBlockType)),
};

export default USPBlock;
