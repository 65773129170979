import { addMinutes, format } from "date-fns";
import { de, enGB as en, es, fr, it, nl, pt } from "date-fns/locale";

const dateLocale = { nl, en, pt, es, it, de, fr };

/**
 * Formats a given date string to HH:mm in the specified locale.
 *
 * @param {string} dateString - The date string in ISO format.
 * @param {string} locale - The locale code.
 * @returns {string} - The formatted time string.
 */
const formatTime = (dateString, locale) => {
  const date = addMinutes(
    new Date(dateString),
    new Date(dateString).getTimezoneOffset(),
  );
  return format(date, "HH:mm", { locale: dateLocale[locale] ?? dateLocale.en });
};

/**
 * Formats a range of event times.
 *
 * @param {Array} dates - Array of date objects with 'from' and 'until' properties.
 * @param {string} [locale='nl'] - Locale for formatting time.
 * @param {string} [multipleTimesLabel='multiple times'] - Label for multiple times.
 * @returns {string} - Formatted event time description.
 *
 * @example
 * // Multiple days with the same time
 * formatCardEventTimes([
 *   { from: '2024-05-07T09:00:00+00:00', until: '2024-05-07T18:00:00+00:00' },
 *   { from: '2024-05-08T09:00:00+00:00', until: '2024-05-08T18:00:00+00:00' },
 * ], 'nl', 'meerdere keren')
 * // Returns: '09:00 - 18:00'
 *
 * @example
 * // Single day with one specific time
 * formatCardEventTimes([{ from: '2024-05-15T09:00:00+00:00', until: '2024-05-15T18:00:00+00:00' }], 'nl', 'meerdere keren')
 * // Returns: '09:00 - 18:00'
 *
 * @example
 * // Multiple times on a single day
 * formatCardEventTimes([
 *   { from: '2024-05-15T09:00:00+00:00', until: '2024-05-15T12:00:00+00:00' },
 *   { from: '2024-05-15T13:00:00+00:00', until: '2024-05-15T15:00:00+00:00' }
 * ], 'nl', 'meerdere keren')
 * // Returns: 'meerdere keren'
 *
 * @example
 * // Multiple days with multiple different times
 * formatCardEventTimes([
 *   { from: '2024-05-07T09:00:00+00:00', until: '2024-05-07T18:00:00+00:00' },
 *   { from: '2024-05-08T10:00:00+00:00', until: '2024-05-08T17:00:00+00:00' }
 * ], 'nl', 'meerdere keren')
 * // Returns: 'meerdere keren'
 */
const formatCardEventTimes = (
  dates = [],
  locale = "nl",
  multipleTimesLabel = "multiple times",
) => {
  if (!Array.isArray(dates) || dates.length === 0) {
    return "";
  }

  const eventTimes = dates.map(({ from, until }) => ({
    startTime: formatTime(from, locale),
    endTime: formatTime(until, locale),
  }));

  const uniqueTimes = new Set(
    eventTimes.map(({ startTime, endTime }) => `${startTime} - ${endTime}`),
  );

  return uniqueTimes.size === 1
    ? uniqueTimes.values().next().value
    : multipleTimesLabel;
};

export default formatCardEventTimes;
